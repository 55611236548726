
import React from "react";
import {
	Card,
	CardBody,
	CardHeader,
	Container,
	Row,
	Col,
	Label
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import moment from 'moment';
import utils from "../../../utils";
import config from "../../../config";

const currentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

export default class Flux extends React.Component {
	state = {
		loading: false,
		artisans: [],
		flux: [],
		selectedArtisan: null,
		selectedMonth: currentMonth,
		customDateInterval: false,
		intervalDateStart: null,
		intervalDateEnd: null,
		commandesLivreesExpandedRows: null,
		filters: {
			commandesLivrees: {
				'professionnel.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
				'livreur.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			},
			cycles: {
				'libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
			},
			cartesPrepayees: {
				'cartePrepayee.numeroSurCarte': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			},
			couponsFideliteUtilises: {
				'professionnel.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
				'libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
			}
		}
	}

	async componentDidMount() {
		const response = await utils.apiRequest.send(`pro?order=libelle&sort=ASC&role=isArtisan`);
		response.result.splice(0, 0, { id: null, libelle: "Tous" });
		this.setState({ artisans: response.result, selectedArtisan: response.result[0] });

		await this.getData();
	}

	getData = async () => {
		try {
			const { selectedArtisan, selectedMonth, customDateInterval, intervalDateStart, intervalDateEnd } = this.state;
			const data = customDateInterval ? { selectedArtisanId: selectedArtisan?.id, intervalDateStart, intervalDateEnd } : { selectedArtisanId: selectedArtisan?.id, selectedMonth };

			this.setState({ loading: true });
			const response = await utils.apiRequest.send('/statistiques/flux/', 'POST', data);
			this.setState({ flux: response.result });
		} catch (error) { }

		this.setState({ loading: false });
	}

	render() {
		const { artisans, loading, selectedArtisan, selectedMonth, customDateInterval, intervalDateStart, intervalDateEnd, flux, commandesLivreesExpandedRows, filters } = this.state;
		return (
			<>
				<SimpleHeader category="Statistiques" name="Flux" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Flux</h3>
									</div>
								</CardHeader>
								<CardBody>
									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Sélection</h3>
											</div>
											<Row className="float-right">
												<Label className="mr-2">Intervalle personnalisé</Label>
												<InputSwitch checked={customDateInterval} onChange={e => this.setState({
													customDateInterval: e.value,
													selectedMonth: e.value ? null : currentMonth
												})} />
											</Row>
										</CardHeader>
										<CardBody>
											<Row>
												<Col xs="auto">
													<Label>Artisan</Label>
													<Row>
														<Dropdown
															optionLabel="libelle" placeholder="Sélectionnez..."
															value={selectedArtisan} options={artisans}
															onChange={e => this.setState({ selectedArtisan: e.value })}
															itemTemplate={o => { return <div>{o.libelle}</div> }}
															filter
															valueTemplate={(o, p) => { return o ? <div>{o.libelle}</div> : <span>{p.placeholder}</span> }}
															style={{ width: '13rem' }}
															className="ml-2 mr-5 w-full md:w-14rem" />
													</Row>
												</Col>

												<Col md="5" className="mr-5">
													{customDateInterval
														? <Row>
															<Col md="4" className="mr-5">
																<Label>Début</Label>
																<Row>
																	<Calendar
																		value={intervalDateStart}
																		onChange={e => this.setState({ intervalDateStart: e.value })}
																		style={{ width: '13rem' }}
																		dateFormat="dd/mm/yy" showIcon />
																</Row>
															</Col>
															<Col md="4" className="mr-5">
																<Label>Fin</Label>
																<Row>
																	<Calendar
																		value={intervalDateEnd}
																		onChange={e => this.setState({ intervalDateEnd: e.value })}
																		style={{ width: '13rem' }}
																		dateFormat="dd/mm/yy" showIcon />
																</Row>
															</Col>
														</Row>
														: <Col>
															<Label>Mois</Label>
															<Row>
																<Calendar
																	value={selectedMonth}
																	onChange={e => this.setState({ selectedMonth: e.value })}
																	style={{ width: '13rem' }}
																	view="month" dateFormat="mm/yy" showIcon />
															</Row>
														</Col>}
												</Col>

												<Col>
													<Button label="Rechercher" icon="pi pi-search" className="float-right" loading={loading} onClick={this.getData} />
												</Col>
											</Row>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Produits commandés livrés</h3>
											</div>
											<div className="float-right">
												<h3 className="mb-0">Total: {utils.pluriel('caisse', flux?.produitsCommandes?.total)}</h3>
											</div>
										</CardHeader>
										<CardBody style={{ padding: 0 }}>
											<DataTable
												dataKey="id" filterDisplay="menu" paginator stripedRows rows={15} size="small" responsiveLayout="scroll" emptyMessage="Aucun élément"
												value={flux?.produitsCommandes?.produits} loading={loading} filters={filters.cycles}>
												<Column field="libelle" header="Produit" sortable filter filterPlaceholder="Saisissez..." />
												<Column header="Caisse(s)" sortable body={value => value.quantitePreparee} />
											</DataTable>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Cycles démarrés</h3>
											</div>
											<div className="float-right">
												<h3 className="mb-0">Total: {utils.pluriel('cycle', flux?.cyclesDemarres?.total)}</h3>
											</div>
										</CardHeader>
										<CardBody style={{ padding: 0 }}>
											<DataTable
												dataKey="id" filterDisplay="menu" paginator stripedRows rows={15} size="small" responsiveLayout="scroll" emptyMessage="Aucun élément"
												value={flux?.cyclesDemarres?.produits} loading={loading} filters={filters.cycles}>
												<Column field="libelle" header="Produit" sortable filter filterPlaceholder="Saisissez..." />
												<Column header="Cycles clients" sortable body={value => value.cyclesClients.length} />
												<Column header="Cycles internes" sortable body={value => value.cyclesInternes.length} />
												<Column header="Total cycles" sortable body={value => value.cyclesClients.length + value.cyclesInternes.length} />
												<Column field="sommeTarifConsigne" header="Montant" sortable body={value => value.sommeTarifConsigne + "€"} />
											</DataTable>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Contenants sales récupérés par les livreurs</h3>
											</div>
											<div className="float-right">
												<h3 className="mb-0">Total: {utils.pluriel('contenant', flux?.saleRecupere?.total)}</h3>
											</div>
										</CardHeader>
										<CardBody style={{ padding: 0 }}>
											<DataTable
												dataKey="id" filterDisplay="menu" paginator stripedRows rows={15} size="small" responsiveLayout="scroll" emptyMessage="Aucun élément"
												value={flux?.saleRecupere?.produits} loading={loading} filters={filters.cycles}
											>
												<Column field="libelle" header="Produit" sortable filter filterPlaceholder="Saisissez..." />
												<Column field="montantConsigne" header="Montant" sortable body={value => value.montantConsigne + "€"} />
												<Column field="quantite" header="Quantité" sortable body={value => value.quantite + ` contenant${value.quantite > 1 ? 's' : ''}`} />
												<Column field="sommeMontantConsigne" header="Total" sortable body={value => value.sommeMontantConsigne + "€"} />
											</DataTable>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Cartes prépayées</h3>
											</div>
											<div className="float-right">
												<h3 className="mb-0">Solde: {flux?.cartesPrepayees?.[0]?.sommeActivations - flux?.cartesPrepayees?.[0]?.sommeRemboursements}€</h3>
											</div>
										</CardHeader>
										<CardBody style={{ padding: 0 }}>
											<Card>
												<CardHeader>
													<div className="float-left">
														<h3 className="mb-0">Activations</h3>
													</div>
												</CardHeader>
												<CardBody style={{ padding: 0 }}>
													<DataTable
														dataKey="id" filterDisplay="menu" paginator stripedRows rows={10} size="small" responsiveLayout="scroll" emptyMessage="Aucun élément"
														value={flux?.cartesPrepayees?.[0]?.activationsCartesPrepayees} loading={loading} filters={filters.cartesPrepayees}
													>
														<Column field="cartePrepayee.numeroSurCarte" header="Carte prépayée" sortable filter filterPlaceholder="Saisissez..." />
														<Column field="createdAt" header="Date d'activation" dataType="date" sortable body={value => moment(value.createdAt).locale('fr').format('DD/MM/YYYY [à] HH:mm')} />
														<Column field="montant" header="Montant de la carte" sortable body={value => value.montant + "€"} />
														<Column field="artisan.libelle" header="Artisan" />
													</DataTable>
												</CardBody>
											</Card>
											<Card>
												<CardHeader>
													<div className="float-left">
														<h3 className="mb-0">Remboursements</h3>
													</div>
												</CardHeader>
												<CardBody style={{ padding: 0 }}>
													<DataTable
														dataKey="id" filterDisplay="menu" paginator stripedRows rows={10} size="small" responsiveLayout="scroll" emptyMessage="Aucun élément"
														value={flux.cartesPrepayees?.[0]?.remboursementsCartesPrepayees} loading={loading} filters={filters.cartesPrepayees}
													>
														<Column field="cartePrepayee.numeroSurCarte" header="Carte prépayée" sortable filter filterPlaceholder="Saisissez..." />
														<Column field="createdAt" header="Date du remboursement" dataType="date" sortable body={value => moment(value.createdAt).locale('fr').format('DD/MM/YYYY [à] HH:mm')} />
														<Column field="montant" header="Montant du remboursement" sortable body={value => value.montant + "€"} />
														<Column field="artisan.libelle" header="Artisan" />
													</DataTable>
												</CardBody>
											</Card>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Coupons de fidélité utilisés</h3>
											</div>
											<div className="float-right">
												<h3 className="mb-0">Total: {utils.pluriel('coupon', flux?.couponsFideliteUtilises?.length)}</h3>
											</div>
										</CardHeader>
										<CardBody style={{ padding: 0 }}>
											<DataTable
												dataKey="id" filterDisplay="menu" paginator stripedRows rows={15} size="small" responsiveLayout="scroll" emptyMessage="Aucun élément"
												value={flux?.couponsFideliteUtilises} loading={loading} filters={filters.couponsFideliteUtilises}>
												<Column field="id" header="#" />
												<Column field="libelle" header="Libellé" sortable filter filterPlaceholder="Saisissez..." />
												<Column field="pointsRequis" header="Points" sortable />
												<Column field="dateUtilisation" header="Date d'utilisation" dataType="date" sortable body={value => moment(value.dateUtilisation).locale('fr').format('DD/MM/YYYY [à] HH:mm')} />
												<Column field="createdAt" header="Date génération utilisateur" dataType="date" sortable body={value => moment(value.createdAt).locale('fr').format('DD/MM/YYYY [à] HH:mm')} />
												<Column field="professionnel.libelle" header="Utilisé chez" sortable />
											</DataTable>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Commandes livrées</h3>
											</div>
											<div className="float-right">
												<h3 className="mb-0">Total: {utils.pluriel('commande', flux?.commandesLivrees?.length)}</h3>
											</div>
										</CardHeader>
										<CardBody style={{ padding: 0 }}>
											<DataTable
												dataKey="id" filterDisplay="menu" responsiveLayout="scroll" emptyMessage="Aucun élément"
												value={flux?.commandesLivrees} loading={loading} filters={filters.commandesLivrees}
												paginator stripedRows rows={15} size="small"
												onRowToggle={(e) => this.setState({ commandesLivreesExpandedRows: e.data })}
												expandedRows={commandesLivreesExpandedRows}
												rowExpansionTemplate={data => {
													return (
														<div className="p-3">
															<h5>Contenu de la commande {data.id}</h5>
															<DataTable value={data.contenu}>
																<Column field="produit.libelle" header="Produit" sortable />
																<Column field="quantitePreparee" header="Quantité caisse" sortable />
																<Column field="nbProduitsParCaisse" header="Nombre par caisse" sortable />
																<Column field="totalContenant" header="Total" sortable body={value => value.totalContenant + ` contenant${value.totalContenant > 1 ? 's' : ''}`} />
															</DataTable>
														</div>
													);
												}}>
												<Column expander={rowData => rowData.contenu.length > 0} style={{ width: '1rem' }} />
												<Column field="id" header="#" />
												<Column field="createdAt" header="Passée le" dataType="date" sortable
													body={value => moment(value.createdAt).locale('fr').format('DD/MM/YYYY [à] HH:mm')} />
												<Column field="commentaire" header="Commentaire" body={value => value.commentaire ?? "Aucun"} />
												<Column field="professionnel.libelle" header="Artisan" sortable filter filterPlaceholder="Saisissez..." />
												<Column field="livreur.libelle" header="Livreur" sortable filter filterPlaceholder="Saisissez..." />
											</DataTable>
										</CardBody>
									</Card>
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container >
			</>
		);
	}
}
