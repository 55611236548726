
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Input,
	Container,
	Row,
	Col,
	Label,
	Button,
	Modal,
	UncontrolledTooltip
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import * as EmailValidator from 'email-validator';
import config from "../../../config";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import utils from "../../../utils";

const horaireOuvertureMatin = '12:00';
const horaireFermetureMatin = '14:30';
const horaireOuvertureApresMidi = '19:00';
const horaireFermetureApresMidi = '22:00';

export default class ArtisanAdd extends React.Component {
	state = {
		redirect: false,
		newUser: {
			email: null,
			libelle: null,
			city: null,
			address: null,
			zipcode: null,
			password: null,
			passwordRepeat: null,
			phone: null,
			isArtisan: true,
			aEmporter: false,
			surPlace: false,
			clickAndCollect: false,
			programmeFidelite: false,
			horaires: {
				1: { // lundi
					horaireOuvertureMatin: horaireOuvertureMatin,
					horaireFermetureMatin: horaireFermetureMatin,
					horaireOuvertureApresMidi: horaireOuvertureApresMidi,
					horaireFermetureApresMidi: horaireFermetureApresMidi,
					ferme: false
				},
				2: {
					horaireOuvertureMatin: horaireOuvertureMatin,
					horaireFermetureMatin: horaireFermetureMatin,
					horaireOuvertureApresMidi: horaireOuvertureApresMidi,
					horaireFermetureApresMidi: horaireFermetureApresMidi,
					ferme: false
				},
				3: {
					horaireOuvertureMatin: horaireOuvertureMatin,
					horaireFermetureMatin: horaireFermetureMatin,
					horaireOuvertureApresMidi: horaireOuvertureApresMidi,
					horaireFermetureApresMidi: horaireFermetureApresMidi,
					ferme: false
				},
				4: {
					horaireOuvertureMatin: horaireOuvertureMatin,
					horaireFermetureMatin: horaireFermetureMatin,
					horaireOuvertureApresMidi: horaireOuvertureApresMidi,
					horaireFermetureApresMidi: horaireFermetureApresMidi,
					ferme: false
				},
				5: {
					horaireOuvertureMatin: horaireOuvertureMatin,
					horaireFermetureMatin: horaireFermetureMatin,
					horaireOuvertureApresMidi: horaireOuvertureApresMidi,
					horaireFermetureApresMidi: horaireFermetureApresMidi,
					ferme: false
				},
				6: {
					horaireOuvertureMatin: horaireOuvertureMatin,
					horaireFermetureMatin: horaireFermetureMatin,
					horaireOuvertureApresMidi: horaireOuvertureApresMidi,
					horaireFermetureApresMidi: horaireFermetureApresMidi,
					ferme: false
				},
				7: { // dimanche
					horaireOuvertureMatin: horaireOuvertureMatin,
					horaireFermetureMatin: horaireFermetureMatin,
					horaireOuvertureApresMidi: horaireOuvertureApresMidi,
					horaireFermetureApresMidi: horaireFermetureApresMidi,
					ferme: true
				}
			}
		},
		isError: {
			email: false,
			libelle: false,
			city: false,
			address: false,
			zipcode: false,
			password: null,
			passwordRepeat: null,
			phone: false,
			role: false,
			aEmporter: false,
			surPlace: false,
			clickAndCollect: false,
			horaires: {
				1: false,
				2: false,
				3: false,
				4: false,
				5: false,
				6: false,
				7: false
			}
		},
		displayConfirmModal: false
	}

	constructor(props) {
		super(props);
		this.notification = React.createRef();
	}

	render() {
		return (
			<>
				<div className="rna-wrapper">
					<NotificationAlert ref={ref => this.notification = ref} />
				</div>
				<SimpleHeader name="Artisans" category="Utilisateurs" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Card className="mb-4">
						<CardHeader>
							<div className="float-left">
								<h3 className="mb-0">Ajouter un compte artisan</h3>
								<p className="text-muted">
									Un compte artisan est un compte qui peut s'authentifier sur l'application Bolhero Pro en tant qu'artisan.
								</p>
							</div>
							<div className="float-right">
								<Button color="success" size="sm" type="button"
									id="createButton"
									onClick={() => this.setState({ displayConfirmModal: true })}
								>
									<span className="btn-inner--icon mr-1">
										<i className="fas fa-user-plus" />
									</span>
									Ajouter
								</Button>
								<UncontrolledTooltip delay={0} target="createButton">
									Ajouter un compte artisan
								</UncontrolledTooltip>
							</div>
						</CardHeader>
						<CardBody>
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Informations</h3>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<FormGroup>
												<Label>Nom de l'établissement</Label>
												<Input
													id="libelle"
													placeholder="Libellé"
													type="text"
													className={this.state.isError.libelle ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															libelle: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Le libellé est incorrect.
												</div>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label>Adresse e-mail</Label>
												<Input
													id="email"
													placeholder="E-mail"
													type="email"
													className={this.state.isError.email ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															email: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													L'adresse e-mail est incorrecte.
												</div>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label>Téléphone</Label>
												<Input
													id="phone"
													placeholder="Téléphone"
													type="tel"
													maxLength={10}
													className={this.state.isError.phone ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															phone: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Le téléphone est incorrect.
												</div>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="4">
											<FormGroup>
												<Label>Adresse</Label>
												<Input
													id="address"
													placeholder="ex: 1 rue Saint Jacques"
													type="text"
													className={this.state.isError.address ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															address: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													L'adresse est incorrecte.
												</div>
											</FormGroup>
										</Col>
										<Col md="4">
											<FormGroup>
												<Label>Ville</Label>
												<Input
													id="city"
													placeholder="ex: Laval"
													type="text"
													className={this.state.isError.city ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															city: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													La ville est incorrecte.
												</div>
											</FormGroup>
										</Col>
										<Col md="4">
											<FormGroup>
												<Label>Code postal</Label>
												<Input
													id="zipcode"
													placeholder="ex: 53000"
													type="text"
													maxLength={5}
													className={this.state.isError.zipcode ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															zipcode: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Le code postal est incorrect.
												</div>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<Label>Mot de passe</Label>
												<Input
													id="password"
													placeholder="Mot de passe"
													type="password"
													className={this.state.isError.password ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															password: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Les mot de passe ne correspondent pas.
												</div>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<Label>Confirmer le mot de passe</Label>
												<Input
													id="passwordrepeat"
													placeholder="Confirmation du mot de passe"
													type="password"
													className={this.state.isError.passwordRepeat ? "is-invalid" : ""}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															passwordRepeat: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Les mot de passe ne correspondent pas.
												</div>
											</FormGroup>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Services</h3>
										<p className="text-muted mb-0">
											Les services proposés par l'artisan. Ils s'afficheront sur la carte de l'application Bolhero.
										</p>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxIsAEmporter"
													type="checkbox"
													defaultChecked={this.state.newUser.aEmporter}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															aEmporter: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxIsAEmporter"
													id="aEmporter"
												>
													A emporter
												</Label>
											</div>
										</Col>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxSurplace"
													type="checkbox"
													defaultChecked={this.state.newUser.surPlace}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															surPlace: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxSurplace"
													id="surPlace"
												>
													Sur place
												</Label>
											</div>
										</Col>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxClickAndCollect"
													type="checkbox"
													defaultChecked={this.state.newUser.clickAndCollect}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															clickAndCollect: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxClickAndCollect"
													id="clickAndCollect"
												>
													Click and collect
												</Label>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Fidélité</h3>
										<p className="text-muted mb-0">
											Si activé, l'artisan pourra proposer à ses clients un programme de fidélité et ses clients gagnerons des points de fidélité à chaque attribution et retour de contenant.
										</p>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxProgrammeFidelite"
													type="checkbox"
													defaultChecked={this.state.newUser.programmeFidelite}
													onChange={element => this.setState({
														newUser: {
															...this.state.newUser,
															programmeFidelite: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxProgrammeFidelite"
													id="programmeFidelite"
												>
													Programme de fidélité
												</Label>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Horaires</h3>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="2">
											<Label className="mt-4">Lundi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[1].ferme}
													defaultValue={this.state.newUser.horaires[1].horaireOuvertureMatin}
													className={this.state.isError.horaires[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																1: {
																	...prevState.newUser.horaires[1],
																	horaireOuvertureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[1].ferme}
													defaultValue={this.state.newUser.horaires[1].horaireFermetureMatin}
													className={this.state.isError.horaires[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																1: {
																	...prevState.newUser.horaires[1],
																	horaireFermetureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[1].ferme}
													defaultValue={this.state.newUser.horaires[1].horaireOuvertureApresMidi}
													className={this.state.isError.horaires[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																1: {
																	...prevState.newUser.horaires[1],
																	horaireOuvertureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[1].ferme}
													defaultValue={this.state.newUser.horaires[1].horaireFermetureApresMidi}
													className={this.state.isError.horaires[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																1: {
																	...prevState.newUser.horaires[1],
																	horaireFermetureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeLundi"
													type="checkbox"
													defaultChecked={this.state.newUser.horaires[1].ferme}
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																1: {
																	...prevState.newUser.horaires[1],
																	ferme: element.target.checked
																}
															}
														}
													}))}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeLundi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Mardi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[2].ferme}
													defaultValue={this.state.newUser.horaires[2].horaireOuvertureMatin}
													className={this.state.isError.horaires[2] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																2: {
																	...prevState.newUser.horaires[2],
																	horaireOuvertureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[2].ferme}
													defaultValue={this.state.newUser.horaires[2].horaireFermetureMatin}
													className={this.state.isError.horaires[2] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																2: {
																	...prevState.newUser.horaires[2],
																	horaireFermetureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[2].ferme}
													defaultValue={this.state.newUser.horaires[2].horaireOuvertureApresMidi}
													className={this.state.isError.horaires[2] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																2: {
																	...prevState.newUser.horaires[2],
																	horaireOuvertureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[2].ferme}
													defaultValue={this.state.newUser.horaires[2].horaireFermetureApresMidi}
													className={this.state.isError.horaires[2] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																2: {
																	...prevState.newUser.horaires[2],
																	horaireFermetureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeMardi"
													type="checkbox"
													defaultChecked={this.state.newUser.horaires[2].ferme}
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																2: {
																	...prevState.newUser.horaires[2],
																	ferme: element.target.checked
																}
															}
														}
													}))}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeMardi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Mercredi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[3].ferme}
													defaultValue={this.state.newUser.horaires[3].horaireOuvertureMatin}
													className={this.state.isError.horaires[3] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																3: {
																	...prevState.newUser.horaires[3],
																	horaireOuvertureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[3].ferme}
													defaultValue={this.state.newUser.horaires[3].horaireFermetureMatin}
													className={this.state.isError.horaires[3] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																3: {
																	...prevState.newUser.horaires[3],
																	horaireFermetureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[3].ferme}
													defaultValue={this.state.newUser.horaires[3].horaireOuvertureApresMidi}
													className={this.state.isError.horaires[3] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																3: {
																	...prevState.newUser.horaires[3],
																	horaireOuvertureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[3].ferme}
													defaultValue={this.state.newUser.horaires[3].horaireFermetureApresMidi}
													className={this.state.isError.horaires[3] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																3: {
																	...prevState.newUser.horaires[3],
																	horaireFermetureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeMercredi"
													type="checkbox"
													defaultChecked={this.state.newUser.horaires[3].ferme}
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																3: {
																	...prevState.newUser.horaires[3],
																	ferme: element.target.checked
																}
															}
														}
													}))}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeMercredi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Jeudi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[4].ferme}
													defaultValue={this.state.newUser.horaires[4].horaireOuvertureMatin}
													className={this.state.isError.horaires[4] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																4: {
																	...prevState.newUser.horaires[4],
																	horaireOuvertureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[4].ferme}
													defaultValue={this.state.newUser.horaires[4].horaireFermetureMatin}
													className={this.state.isError.horaires[4] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																4: {
																	...prevState.newUser.horaires[4],
																	horaireFermetureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[4].ferme}
													defaultValue={this.state.newUser.horaires[4].horaireOuvertureApresMidi}
													className={this.state.isError.horaires[4] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																4: {
																	...prevState.newUser.horaires[4],
																	horaireOuvertureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[4].ferme}
													defaultValue={this.state.newUser.horaires[4].horaireFermetureApresMidi}
													className={this.state.isError.horaires[4] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																4: {
																	...prevState.newUser.horaires[4],
																	horaireFermetureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeJeudi"
													type="checkbox"
													defaultChecked={this.state.newUser.horaires[4].ferme}
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																4: {
																	...prevState.newUser.horaires[4],
																	ferme: element.target.checked
																}
															}
														}
													}))}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeJeudi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Vendredi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[5].ferme}
													defaultValue={this.state.newUser.horaires[5].horaireOuvertureMatin}
													className={this.state.isError.horaires[5] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																5: {
																	...prevState.newUser.horaires[5],
																	horaireOuvertureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[5].ferme}
													defaultValue={this.state.newUser.horaires[5].horaireFermetureMatin}
													className={this.state.isError.horaires[5] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																5: {
																	...prevState.newUser.horaires[5],
																	horaireFermetureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[5].ferme}
													defaultValue={this.state.newUser.horaires[5].horaireOuvertureApresMidi}
													className={this.state.isError.horaires[5] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																5: {
																	...prevState.newUser.horaires[5],
																	horaireOuvertureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[5].ferme}
													defaultValue={this.state.newUser.horaires[5].horaireFermetureApresMidi}
													className={this.state.isError.horaires[5] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																5: {
																	...prevState.newUser.horaires[5],
																	horaireFermetureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeVendredi"
													type="checkbox"
													defaultChecked={this.state.newUser.horaires[5].ferme}
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																5: {
																	...prevState.newUser.horaires[5],
																	ferme: element.target.checked
																}
															}
														}
													}))}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeVendredi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Samedi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[6].ferme}
													defaultValue={this.state.newUser.horaires[6].horaireOuvertureMatin}
													className={this.state.isError.horaires[6] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																6: {
																	...prevState.newUser.horaires[6],
																	horaireOuvertureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[6].ferme}
													defaultValue={this.state.newUser.horaires[6].horaireFermetureMatin}
													className={this.state.isError.horaires[6] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																6: {
																	...prevState.newUser.horaires[6],
																	horaireFermetureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[6].ferme}
													defaultValue={this.state.newUser.horaires[6].horaireOuvertureApresMidi}
													className={this.state.isError.horaires[6] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																6: {
																	...prevState.newUser.horaires[6],
																	horaireOuvertureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[6].ferme}
													defaultValue={this.state.newUser.horaires[6].horaireFermetureApresMidi}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																6: {
																	...prevState.newUser.horaires[6],
																	horaireFermetureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeSamedi"
													type="checkbox"
													defaultChecked={this.state.newUser.horaires[6].ferme}
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																6: {
																	...prevState.newUser.horaires[6],
																	ferme: element.target.checked
																}
															}
														}
													}))}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeSamedi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Dimanche</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[7].ferme}
													defaultValue={this.state.newUser.horaires[7].horaireOuvertureMatin}
													className={this.state.isError.horaires[7] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																7: {
																	...prevState.newUser.horaires[7],
																	horaireOuvertureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.newUser.horaires[7].ferme}
													defaultValue={this.state.newUser.horaires[7].horaireFermetureMatin}
													className={this.state.isError.horaires[7] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																7: {
																	...prevState.newUser.horaires[7],
																	horaireFermetureMatin: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[7].ferme}
													defaultValue={this.state.newUser.horaires[7].horaireOuvertureApresMidi}
													className={this.state.isError.horaires[7] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																7: {
																	...prevState.newUser.horaires[7],
																	horaireOuvertureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.newUser.horaires[7].ferme}
													defaultValue={this.state.newUser.horaires[7].horaireFermetureApresMidi}
													className={this.state.isError.horaires[7] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																7: {
																	...prevState.newUser.horaires[7],
																	horaireFermetureApresMidi: element.target.value
																}
															}
														}
													}))}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeDimanche"
													type="checkbox"
													defaultChecked={this.state.newUser.horaires[7].ferme}
													onChange={element => this.setState(prevState => ({
														...prevState,
														newUser: {
															...prevState.newUser,
															horaires: {
																...prevState.newUser.horaires,
																7: {
																	...prevState.newUser.horaires[7],
																	ferme: element.target.checked
																}
															}
														}
													}))}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeDimanche"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</CardBody>
					</Card>
					<Modal
						className="modal-dialog-centered modal-danger"
						contentClassName="bg-gradient-success"
						isOpen={this.state.displayConfirmModal}
					>
						<div className="modal-header">
							<h6
								className="modal-title"
								id="modal-title-notification"
							>
								Confirmation
							</h6>
							<button
								aria-label="Close"
								className="close"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayConfirmModal: false })}
							>
								<span aria-hidden={true}>×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="py-3 text-center">
								<i className="ni ni-bell-55 ni-3x" />
								<h4 className="heading mt-4">
									Attention!
								</h4>
								<p>
									Vous êtes sur le point d'ajouter un artisan.
								</p>
							</div>
						</div>
						<div className="modal-footer">
							<Button
								className="btn-white"
								color="default"
								type="button"
								onClick={() => this.addUser()}
							>
								Ajouter
							</Button>
							<Button
								className="text-white ml-auto"
								color="link"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayConfirmModal: false })}
							>
								Annuler
							</Button>
						</div>
					</Modal>
					{this.state.redirect && <Redirect to="/admin/artisans" />}
				</Container >
			</>
		);
	}

	addUser = async () => {
		try {
			const URL = '/pro/registerWithCredentials';
			this.setState({ displayConfirmModal: false });

			if (this.checkInputs()) {
				let response = await utils.apiRequest.send(URL, 'PUT', this.state.newUser);

				if (response.result) {
					utils.alert.notify('success', 'Le compte a été créé.', this.notification);
					setTimeout(() => {
						this.setState({ redirect: true })
					}, 1000);
				} else {
					throw new Error();
				}
			} else {
				utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
			}
		} catch (error) {
			if (error.message.endsWith('409')) {
				utils.alert.notify('warning', 'Cette adresse email est déjà utilisée.', this.notification);
			} else {
				utils.alert.notify('danger', 'Il y a eu une erreur lors de l\'ajout.', this.notification);
			}
		}
	}

	checkInputs = () => {
		let { isError } = this.state;
		let { newUser } = this.state;
		let areInputsCorrect = true;

		for (const [key, value] of Object.entries(newUser)) {
			switch (key) {
				case 'libelle':
					isError[key] = value == null || value.length < 2;
					break;
				case 'city':
					isError[key] = value == null || value.length < 2;
					break;
				case 'zipcode':
					isError[key] = value == null || value.length < 2;
					break;
				case 'address':
					isError[key] = value == null || value.length < 2;
					break;
				case 'email':
					isError[key] = !EmailValidator.validate(this.state.newUser[key]);
					break;
				case 'phone':
					isError[key] = value?.length > 0 ? (value.length !== 10 || isNaN(value)) : false;
					break;
				case 'password':
					isError[key] = (this.state.newUser.password !== this.state.newUser.passwordRepeat || value == null);
					break;
				case 'passwordRepeat':
					isError[key] = (this.state.newUser.password !== this.state.newUser.passwordRepeat || value == null);
					break;
				case 'horaires':
					for (let jour in value) {
						const horaire = value[jour];
						isError[key][jour] = !horaire.ferme && (horaire.horaireOuvertureMatin == null
							|| horaire.horaireFermetureMatin == null
							|| horaire.horaireOuvertureApresMidi == null
							|| horaire.horaireFermetureApresMidi == null);
					}
					break;
				default:
					break;
			}
		}
		this.setState({ isError });

		// Retourne false si un des champs est incorrect
		for (const [key, value] of Object.entries(isError)) {
			if (typeof value === 'object') {
				if (Object.values(value).some(v => v === true)) {
					areInputsCorrect = false;
				}
			} else if (value === true) {
				areInputsCorrect = false;
			}

			if (key) { }
		}

		return areInputsCorrect;
	}
}
