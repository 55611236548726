
import React, { useEffect } from "react";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";
import UserAvatar from 'react-user-avatar';
import CardsHeader from "components/Headers/CardsHeader.js";
import {
  chartOptions,
  parseOptions,
  chartConsignes,
  chartConsignesHs,
} from "variables/charts.js";
import moment from 'moment';
import utils from '../../utils';
import config from "../../config";
import classnames from "classnames";

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [statistics, setStatistics] = React.useState([]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  const getStatistics = async () => {
    const statistics = await utils.apiRequest.send('/statistiques/adminDashboard');
    setStatistics(statistics.result);
  }

  useEffect(() => {
    getStatistics();
  }, []);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <CardsHeader name="Tableau de bord" parentName={config.app.name} statistics={statistics} />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="8">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      {statistics.graphiqueConsignesHs?.length !== 0 ? 'Consignes utilisées' : 'Chargement...'}
                    </h6>
                    <h5 className="h3 text-white mb-0">Cette {activeNav === 1 ? 'semaine' : 'année'}</h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Semaine</span>
                          <span className="d-md-none">S</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Année</span>
                          <span className="d-md-none">A</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={statistics.graphiqueConsignes?.[activeNav === 1 ? 0 : 1]}
                    options={chartConsignes.options}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      {statistics.graphiqueConsignesHs?.length !== 0 ? 'Consignes hors service' : 'Chargement...'}
                    </h6>
                    <h5 className="h3 mb-0">Année {moment().format('YYYY')}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={statistics.graphiqueConsignesHs}
                    options={chartConsignesHs.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Administration</h5>
              </CardHeader>
              <CardBody>
                <ListGroup className="list my--3" flush>
                  {Object.keys(statistics).length > 0 && statistics.administrateurs?.map((admin, index) => {
                    return (
                      <ListGroupItem className="px-0" key={index}>
                        <Row className="align-items-center mt--1">
                          <Col className="col-auto">
                            <div
                              className="avatar rounded-circle"
                              onClick={(e) => e.preventDefault()}
                            >
                              <UserAvatar size="48" name={admin.firstname + ' ' + admin.lastname} color="#8F8F8F" />

                            </div>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <div>
                                {admin.firstname + ' ' + admin.lastname}
                              </div>
                            </h4>
                            <span className="text-primary"><i className="far fa-envelope" /></span>{" "}
                            <small>{admin.email}</small>
                            <Row>
                              {admin.isAdmin === 1 &&
                                <span className="ml-3">
                                  <span className="text-primary"><i className="fas fa-user-shield" /></span>{" "}
                                  <small>Administrateur général</small>
                                </span>}
                              {(admin.isAdmin === 0 && admin.isSimulationAdmin === 1) &&
                                <span className="ml-3">
                                  <span className="text-primary"><i className="fas fa-receipt" /></span>{" "}
                                  <small>Simulations</small>
                                </span>}
                              {(admin.isAdmin === 0 && admin.isContactAdmin === 1) &&
                                <span className="ml-3">
                                  <span className="text-primary"><i className="fas fa-file-signature" /></span>{" "}
                                  <small>Contact</small>
                                </span>}
                            </Row>
                          </div>
                        </Row>
                      </ListGroupItem>
                    )
                  })}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
