
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import utils from '../../../utils';
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";

export default class ActualiteEdit extends React.Component {
    state = {
        entry: this.props.location.state.entry,
        displayDeleteModal: false,
        displayUpdateModal: false,
        redirect: false,
        updatedEntry: {
            titre: this.props.location.state.entry.titre,
            texte: this.props.location.state.entry.texte,
            image: this.props.location.state.entry.image,
            url: this.props.location.state.entry.url
        },
        isError: {
            titre: false,
            texte: false
        }
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Actualités" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">{this.state.entry.titre}</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="updateButton"
                                    onClick={() => this.setState({ displayUpdateModal: true })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-edit" />
                                    </span>
                                    Mettre à jour
                                </Button>
                                <UncontrolledTooltip delay={0} target="updateButton">
                                    Enregistrer les modifications
                                </UncontrolledTooltip>
                                <Button color="danger" size="sm" type="button"
                                    id="deleteButton"
                                    onClick={() => this.setState({ displayDeleteModal: true })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-trash" />
                                    </span>
                                    Supprimer
                                </Button>
                                <UncontrolledTooltip delay={0} target="deleteButton">
                                    Supprimer l'élement
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="8">
                                    <FormGroup>
                                        <Label>Titre</Label>
                                        <Input
                                            id="titre"
                                            placeholder="Titre"
                                            type="text"
                                            className={this.state.isError.titre ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.titre}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    titre: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le titre est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Image</Label>
                                        <Form>
                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="customFileLang"
                                                    lang="fr"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={element => {
                                                        this.setState({
                                                            updatedEntry: {
                                                                ...this.state.updatedEntry,
                                                                image: element.target.files[0]
                                                            }
                                                        })
                                                    }}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="customFileLang"
                                                >
                                                </label>
                                            </div>
                                        </Form>
                                        <div className="invalid-feedback">
                                            L'image est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="8">
                                    <FormGroup>
                                        <Label>Texte</Label>
                                        <Input
                                            rows="10"
                                            type="textarea"
                                            className={this.state.isError.texte ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.texte}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    texte: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le texte est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                {this.state.entry.image &&
                                    <Col md="4">
                                        <Label>Image actuelle</Label>
                                        <div className="text-centered mb-3">
                                            <img
                                                src={`${config.api.endpoint}/actualite/image/${this.state.entry.id}`}
                                                alt="Actualite"
                                                width="200"
                                                height="200"
                                                className="img-fluid rounded" />
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Lien</Label>
                                        <Input
                                            id="url"
                                            placeholder="https://www.google.com/"
                                            defaultValue={this.state.entry.url}
                                            type="text"
                                            className={this.state.isError.url ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    url: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'url est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className="text-muted">Publiée le {moment(this.state.entry.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                </Col>
                            </Row>
                            {this.state.entry.updatedAt !== this.state.entry.createdAt &&
                                <Row>
                                    <Col>
                                        <h5 className="text-muted">Mise à jour le {moment(this.state.entry.updatedAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                    </Col>
                                </Row>}
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-danger"
                        isOpen={this.state.displayDeleteModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Confirmation
                                </h4>
                                <p>
                                    Vous êtes sur le point du supprimer la publication "{this.state.entry.titre}",
                                    cette action est irréversible.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.delete()}
                            >
                                Supprimer
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-info"
                        isOpen={this.state.displayUpdateModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayUpdateModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Confirmation
                                </h4>
                                <p>
                                    Vous êtes sur le point de mettre à jour la publication "{this.state.entry.titre}",
                                    cette action est irréversible.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.update()}
                            >
                                Mettre à jour
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayUpdateModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/actualites" />}
                </Container>
            </>
        );
    }

    delete = async () => {
        try {
            const URL = `/actualite/delete/${this.state.entry.id}`;
            this.setState({ displayDeleteModal: false });

            let response = await utils.apiRequest.send(URL, 'DELETE');

            if (response.result) {
                utils.alert.notify('success', 'L\'entrée a été supprimée.', this.notification);
                setTimeout(() => {
                    this.setState({ redirect: true })
                }, 1000)
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la suppression.', this.notification);
            }
        }
    }

    update = async () => {
        try {
            const URL = `/actualite/update/${this.state.entry.id}`;
            this.setState({ displayUpdateModal: false });

            if (this.checkInputs()) {
                const { titre, texte, image, url } = this.state.updatedEntry;

                let formData = new FormData();
                if (titre) formData.append('titre', titre);
                if (texte) formData.append('texte', texte);
                if (image) formData.append('image', image);
                if (url) formData.append('url', url);

                let response = await utils.apiRequest.send(URL, 'POST', formData);

                if (response.result) {
                    utils.alert.notify('success', 'L\'entrée a été mise à jour.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000)
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la mise à jour.', this.notification);
            }
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { updatedEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(updatedEntry)) {
            switch (key) {
                case 'image':
                    isError[key] = value < 1;
                    break;
                case 'url':
                    if (value !== null && value?.length !== 0) {
                        // eslint-disable-next-line 
                        let regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
                        isError[key] = !regex.test(value);
                    } else {
                        isError[key] = false;
                    }
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
