
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Button
} from "reactstrap";
import { Link } from "react-router-dom";
import utils from "../../../utils/index";
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";

export default class Orders extends React.Component {
    state = {
        entries: [],
        loading: true,
        filters: {
            'professionnel.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
        }
    }

    async componentDidMount() {
        const response = await utils.apiRequest.send('/commandeProduit');
        if (response.result) {
            this.setState({ entries: response.result });
        }
        this.setState({ loading: false });
    }

    render() {
        const { entries, filters, loading } = this.state;

        return (
            <>
                <SimpleHeader name="Commandes" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Commandes</h3>
                                        <p className="text-muted">
                                            Liste des commandes passées par les artisans depuis l'application Bolhero Pro.
                                        </p>
                                    </div>
                                    <div className="float-right">
                                        <Link className="table-action" to={{
                                            pathname: "/admin/order/add"
                                        }} >
                                            <Button color="success" size="sm" type="button"
                                                id="createButton">

                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-plus" />
                                                </span>
                                                Ajouter
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="createButton" >
                                                Créer une nouvelle commande
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </CardHeader>

                                <DataTable value={entries} filters={filters}
                                    paginator className="p-datatable-customers" stripedRows size="small" rows={20}
                                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="Aucun élément">
                                    <Column field="id" header="#" />
                                    <Column field="professionnel.libelle" header="Artisan" filter filterPlaceholder="Saisissez..." sortable />
                                    <Column field="createdAt" header="Passée le" dataType="date" sortable
                                        style={{ minWidth: '15rem' }}
                                        body={value => moment(value.createdAt).locale('fr').format('dddd Do MMMM YYYY [à] HH[h]mm')} />
                                    <Column field="administrateur" header="Passée par"
                                        body={value => value.administrateur !== null ? 'Bolhero (' + value.administrateur.firstname + ' ' + value.administrateur.lastname.toUpperCase() + ')' : "L'artisan"}
                                        style={{ minWidth: '10rem' }} />
                                    <Column field="preparee" header="Statut" sortable
                                        body={value => value.receptionnee ? "Livrée" : value.preparee ? "Envoyée" : "En préparation"} />
                                    <Column header="Actions"
                                        body={order =>
                                            <div>
                                                <Link className="table-action" to={{
                                                    pathname: "/admin/order/edit",
                                                    state: { order: order }
                                                }} >
                                                    <Button color="primary" size="sm" type="button">
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-edit" />
                                                        </span>
                                                        Éditer
                                                    </Button>
                                                </Link>
                                            </div>
                                        }
                                        style={{ minWidth: '8rem' }} />
                                </DataTable>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}
