
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip
} from "reactstrap";
import { Redirect } from "react-router";
import QrReader from 'react-qr-scanner'
import NotificationAlert from "react-notification-alert";
import config from "../../../config";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import utils from "../../../utils/index";
import moment from 'moment';

export default class ConsigneAdd extends React.Component {
    state = {
        redirect: false,
        newEntry: {
            numero: '',
            dateMiseService: moment().format('YYYY-MM-DD[T]hh:mm'),
            produitId: null
        },
        produits: [],
        isError: {
            numero: false,
            dateMiseService: false,
            produitId: false
        },
        displayConfirmModal: false,
        displayQrCodeScanner: false
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
        this.handleScan = this.handleScan.bind(this)
    }

    async componentDidMount() {
        const produits = await utils.getDataDropdown('produit');
        this.setState({ produits: produits });
    }

    handleScan(data) {
        let numeroConsigne = data?.text?.split('/').pop();
        if (numeroConsigne?.length > 0) {
            this.setState({
                displayQrCodeScanner: false,
                newEntry: {
                    ...this.state.newEntry,
                    numero: numeroConsigne
                }
            });
        }
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Consignes" category="Produits" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Ajouter une consigne</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="createButton"
                                    onClick={() => this.setState({ displayConfirmModal: true })}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-plus" />
                                    </span>
                                    Ajouter
                                </Button>
                                <UncontrolledTooltip delay={0} target="createButton">
                                    Ajouter une consigne
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Numéro</Label>
                                        <i className="fas fa-info-circle ml-1" id="numeroHelp" style={{ fontSize: '16px' }} />
                                        <UncontrolledTooltip delay={0} target="numeroHelp">
                                            Laissez vide pour générer automatiquement un numéro
                                        </UncontrolledTooltip>

                                        <Button color="primary-outline" size="sm" type="button"
                                            onClick={() => this.setState({ displayQrCodeScanner: !this.state.displayQrCodeScanner })}>
                                            <span className="btn-inner--icon mr-1">
                                                {this.state.displayQrCodeScanner ?
                                                    <i className="fas fa-keyboard" />
                                                    : <i className="fas fa-camera" />}
                                            </span>
                                            {this.state.displayQrCodeScanner ? "Clavier" : "Scanner"}
                                        </Button>

                                        <Input
                                            id="numero"
                                            placeholder="ex: 19832"
                                            type="text"
                                            className={this.state.isError.numero ? "is-invalid" : ""}
                                            value={this.state.newEntry.numero}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    numero: element.target.value
                                                }
                                            })}
                                            disabled={this.state.displayQrCodeScanner}
                                        />
                                        <div className="invalid-feedback">
                                            Le numéro est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Date de mise en service</Label>
                                        <Input
                                            type="datetime-local"
                                            className={this.state.isError.dateMiseService ? "is-invalid" : ""}
                                            defaultValue={moment().format('YYYY-MM-DD[T]hh:mm')}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    dateMiseService: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La date est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {(this.state.displayQrCodeScanner) &&
                                <Row>
                                    <Col className="mt-3">
                                        <QrReader
                                            delay={1000}
                                            style={{ height: 240, width: 320 }}
                                            onScan={this.handleScan}
                                            onError={() => console.log('Erreur avec le scan')}
                                            facingmode="rear"
                                        />
                                    </Col>
                                </Row>}
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Produit</Label>

                                        <Input type="select"
                                            className={this.state.isError.produitId ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    produitId: element.target.value
                                                }
                                            })}>
                                            {this.state.produits.map((type, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {type}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Input>
                                        <div className="invalid-feedback">
                                            Le produit est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-success"
                        isOpen={this.state.displayConfirmModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point d'ajouter une entrée.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.add()}
                            >
                                Ajouter
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/consignes" />}
                </Container >
            </>
        );
    }

    add = async () => {
        try {
            const URL = '/consigne/add';
            this.setState({ displayConfirmModal: false });

            if (this.checkInputs()) {
                let response = await utils.apiRequest.send(URL, 'PUT', this.state.newEntry);

                if (response.result) {
                    utils.alert.notify('success', 'L\'entrée a été ajoutée.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000);
                } else {
                    utils.alert.notify('warning', response.error, this.notification);
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            utils.alert.notify('danger', 'Il y a eu une erreur lors de l\'ajout.', this.notification);
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { newEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(newEntry)) {
            switch (key) {
                case 'numero':
                    isError[key] = value == null || value === '' || value.length === 0;
                    break;
                case 'dateMiseService':
                    isError[key] = !moment(value).isValid();
                    break;
                case 'produitId':
                    isError[key] = value == null || isNaN(value) || value.length === 0;
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
