
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
    Form
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";
import utils from "../../../utils/index";

export default class ProductTypeEdit extends React.Component {
    state = {
        entry: this.props.location.state.entry,
        displayDeleteModal: false,
        displayUpdateModal: false,
        redirect: false,
        updatedEntry: {
            reference: this.props.location.state.entry.reference,
            referenceEtiquette: this.props.location.state.entry.referenceEtiquette,
            libelle: this.props.location.state.entry.libelle,
            taille: this.props.location.state.entry.taille,
            noteEnvironnementale: this.props.location.state.entry.noteEnvironnementale,
            contenance: this.props.location.state.entry.contenance,
            nbParCaisse: this.props.location.state.entry.nbParCaisse,
            nbJetons: this.props.location.state.entry.nbJetons,
            montantConsigne: this.props.location.state.entry.montantConsigne,
            typeProduitId: this.props.location.state.entry.typeProduitId,
            typeJetonId: this.props.location.state.entry.typeJetonId,
            image: this.props.location.state.entry.image
        },
        typesProduit: [],
        typesJeton: [],
        isError: {
            libelle: false,
            reference: false,
            referenceEtiquette: false,
            taille: false,
            noteEnvironnementale: false,
            contenance: false,
            nbParCaisse: false,
            nbJetons: false,
            montantConsigne: false,
            typeProduitId: false,
            typeJetonId: false
        }
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        const typesProduit = await utils.getDataDropdown('typeProduit');
        const typesJeton = await utils.getDataDropdown('typeJeton');
        this.setState({ typesProduit: typesProduit, typesJeton: typesJeton });
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Produit" category="Produits" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">{this.state.entry.libelle} {this.state.entry.isDemo ? "(Produit de démo)" : ""}</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="updateButton"
                                    onClick={() => this.setState({ displayUpdateModal: true })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-edit" />
                                    </span>
                                    Mettre à jour
                                </Button>
                                <UncontrolledTooltip delay={0} target="updateButton">
                                    Enregistrer les modifications
                                </UncontrolledTooltip>
                                <Button color="danger" size="sm" type="button"
                                    id="deleteButton"
                                    onClick={() => this.setState({ displayDeleteModal: true })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-trash" />
                                    </span>
                                    Supprimer
                                </Button>
                                <UncontrolledTooltip delay={0} target="deleteButton">
                                    Supprimer l'élement
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Référence</Label>
                                        <Input
                                            id="reference"
                                            placeholder="ex: 93983"
                                            type="text"
                                            className={this.state.isError.reference ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.reference}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    reference: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La référence est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Référence étiquette</Label>
                                        <Input
                                            id="referenceEtiquette"
                                            placeholder="ex: BH22"
                                            type="text"
                                            className={this.state.isError.referenceEtiquette ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.referenceEtiquette}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    referenceEtiquette: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La référence est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input
                                            id="libelle"
                                            placeholder="Libellé"
                                            type="text"
                                            className={this.state.isError.libelle ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.libelle}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    libelle: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le libellé est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Nombre de produit par caisse</Label>
                                        <Input
                                            id="nbParCaisse"
                                            placeholder="50"
                                            type="text"
                                            className={this.state.isError.nbParCaisse ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.nbParCaisse}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    nbParCaisse: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le nombre par caisse est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Montant de la consigne (€)</Label>
                                        <Input
                                            id="montantConsigne"
                                            placeholder="1"
                                            type="number"
                                            className={this.state.isError.montantConsigne ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.montantConsigne}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    montantConsigne: element.target.value
                                                }
                                            })}
											disabled={this.state.entry.isDemo}
                                        />
                                        <div className="invalid-feedback">
                                            Le montant de la consigne est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Contenance (en cc)</Label>
                                        <Input
                                            id="contenance"
                                            placeholder="ex: 210"
                                            type="text"
                                            className={this.state.isError.contenance ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.contenance}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    contenance: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La contenance est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Taille (en mm)</Label>
                                        <Input
                                            id="taille"
                                            placeholder="ex: Ø 70x50"
                                            type="text"
                                            className={this.state.isError.taille ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.taille}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    taille: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La taille est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Note environnementale</Label>
                                        <Input
                                            id="noteEnvironnementale"
                                            placeholder="ex: A-"
                                            type="text"
                                            className={this.state.isError.noteEnvironnementale ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.noteEnvironnementale}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    noteEnvironnementale: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La note est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Type de produit</Label>
                                        <Input type="select"
                                            className={this.state.isError.typeProduitId ? "is-invalid" : ""}
                                            value={this.state.updatedEntry.typeProduitId}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    typeProduitId: element.target.value
                                                }
                                            })}>
                                            {this.state.typesProduit.map((type, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {type}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Input>
                                        <div className="invalid-feedback">
                                            Le type de produit est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Type de jeton</Label>
                                        <Input type="select"
                                            className={this.state.isError.typeJetonId ? "is-invalid" : ""}
                                            value={this.state.updatedEntry.typeJetonId}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    typeJetonId: element.target.value
                                                }
                                            })}>
                                            {this.state.typesJeton.map((type, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {type}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Input>
                                        <div className="invalid-feedback">
                                            Le type de jeton est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Nombre de jetons par produit</Label>
                                        <Input
                                            id="nbJetons"
                                            placeholder="1"
                                            type="text"
                                            className={this.state.isError.nbJetons ? "is-invalid" : ""}
                                            defaultValue={this.state.entry.nbJetons}
                                            onChange={element => this.setState({
                                                updatedEntry: {
                                                    ...this.state.updatedEntry,
                                                    nbJetons: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le nombre de jetons est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Image</Label>
                                        <Form>
                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="customFileLang"
                                                    lang="fr"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={element => this.setState({
                                                        updatedEntry: {
                                                            ...this.state.updatedEntry,
                                                            image: element.target.files[0]
                                                        }
                                                    })}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="customFileLang"
                                                >
                                                </label>
                                            </div>
                                        </Form>
                                        <div className="invalid-feedback">
                                            L'image est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                {this.state.entry.image &&
                                    <Col md="6">
                                        <Label>Image actuelle</Label>
                                        <div className="text-centered mb-3">
                                            <img
                                                src={`${config.api.endpoint}/produit/image/${this.state.entry.id}`}
                                                alt="Jeton"
                                                width="200"
                                                height="200"
                                                className="img-fluid rounded" />
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className="text-muted">Ajouté le {moment(this.state.entry.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                </Col>
                            </Row>
                            {this.state.entry.updatedAt !== this.state.entry.createdAt &&
                                <Row>
                                    <Col>
                                        <h5 className="text-muted">Mis à jour le {moment(this.state.entry.updatedAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                    </Col>
                                </Row>}
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-danger"
                        isOpen={this.state.displayDeleteModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point du supprimer l'entrée {this.state.entry.libelle},
                                    cette action est irréversible.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.delete()}
                            >
                                Supprimer
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-info"
                        isOpen={this.state.displayUpdateModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayUpdateModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point de mettre à jour l'entrée {this.state.entry.libelle},
                                    cette action est irréversible.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.update()}
                            >
                                Mettre à jour
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayUpdateModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/products" />}
                </Container>
            </>
        );
    }

    delete = async () => {
        try {
            const URL = `/produit/delete/${this.state.entry.id}`;
            this.setState({ displayDeleteModal: false });

            let response = await utils.apiRequest.send(URL, 'DELETE');

            if (response.error) {
                utils.alert.notify('warning', response.error, this.notification);
            } else if (response.result) {
                utils.alert.notify('success', 'L\'entrée a été supprimée.', this.notification);
                setTimeout(() => {
                    this.setState({ redirect: true })
                }, 1000)
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la suppression.', this.notification);
            }
        }
    }

    update = async () => {
        try {
            const URL = `/produit/update/${this.state.entry.id}`;
            this.setState({ displayUpdateModal: false });

            if (this.checkInputs()) {
                let formData = new FormData();
                for (const [key, value] of Object.entries(this.state.updatedEntry)) {
                    if (value !== null) {
                        formData.append(key, value);
                    }
                }

                let response = await utils.apiRequest.send(URL, 'POST', formData);

                if (response.result) {
                    utils.alert.notify('success', 'L\'entrée a été mise à jour.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000)
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la mise à jour.', this.notification);
            }
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { updatedEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(updatedEntry)) {
            switch (key) {
                case 'libelle':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'reference':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'referenceEtiquette':
                    isError[key] = value == null || value.length < 2 || value.length > 6;
                    break;
                case 'contenance':
                    isError[key] = /[a-zA-Z]/g.test(value);
                    break;
                case 'nbParCaisse':
                    isError[key] = parseInt(value) === 0 || isNaN(parseInt(value)) || value == null
                    break;
                case 'nbJetons':
                    isError[key] = parseInt(value) === 0 || isNaN(parseInt(value)) || value == null
                    break;
                case 'montantConsigne':
                    isError[key] = parseInt(value) === 0 || isNaN(value) || value == null
                    break;
                case 'taille':
                    isError[key] = value?.includes('m');
                    break;
                case 'typeProduitId':
                    isError[key] = value == null || isNaN(value) || value.length === 0;
                    break;
                case 'typeJetonId':
                    isError[key] = value == null || isNaN(value) || value.length === 0;
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
