
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import * as EmailValidator from 'email-validator';
import config from "../../../config";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import utils from "../../../utils";

export default class LaveurAdd extends React.Component {
    state = {
        redirect: false,
        newUser: {
            email: null,
            libelle: null,
            city: null,
            address: null,
            zipcode: null,
            password: null,
            passwordRepeat: null,
            phone: null,
            isLaveur: true
        },
        isError: {
            email: false,
            libelle: false,
            city: false,
            address: false,
            zipcode: false,
            password: null,
            passwordRepeat: null,
            phone: false
        },
        displayConfirmModal: false
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Laveurs" category="Utilisateurs" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Ajouter un compte laveur</h3>
                                <p className="text-muted">
                                    Un compte laveur est un compte qui peut s'authentifier sur l'application Bolhero Pro en tant que laveur.
                                </p>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="createButton"
                                    onClick={() => this.setState({ displayConfirmModal: true })}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-user-plus" />
                                    </span>
                                    Ajouter
                                </Button>
                                <UncontrolledTooltip delay={0} target="createButton">
                                    Ajouter un compte laveur
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Nom de l'établissement</Label>
                                        <Input
                                            id="libelle"
                                            placeholder="Libellé"
                                            type="text"
                                            className={this.state.isError.libelle ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    libelle: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le libellé est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Adresse e-mail</Label>
                                        <Input
                                            id="email"
                                            placeholder="E-mail"
                                            type="email"
                                            className={this.state.isError.email ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    email: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'adresse e-mail est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Téléphone</Label>
                                        <Input
                                            id="phone"
                                            placeholder="Téléphone"
                                            type="tel"
                                            maxLength={10}
                                            className={this.state.isError.phone ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    phone: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le téléphone est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                            <Col md="4">
                                    <FormGroup>
                                        <Label>Adresse</Label>
                                        <Input
                                            id="address"
                                            placeholder="ex: 1 rue Saint Jacques"
                                            type="text"
                                            className={this.state.isError.address ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    address: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'adresse est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Ville</Label>
                                        <Input
                                            id="city"
                                            placeholder="ex: Laval"
                                            type="text"
                                            className={this.state.isError.city ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    city: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La ville est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Code postal</Label>
                                        <Input
                                            id="zipcode"
                                            placeholder="ex: 53000"
                                            type="text"
                                            maxLength={5}
                                            className={this.state.isError.zipcode ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    zipcode: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le code postal est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Mot de passe</Label>
                                        <Input
                                            id="password"
                                            placeholder="Mot de passe"
                                            type="password"
                                            className={this.state.isError.password ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    password: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Les mot de passe ne correspondent pas.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Confirmer le mot de passe</Label>
                                        <Input
                                            id="passwordrepeat"
                                            placeholder="Confirmation du mot de passe"
                                            type="password"
                                            className={this.state.isError.passwordRepeat ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newUser: {
                                                    ...this.state.newUser,
                                                    passwordRepeat: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Les mot de passe ne correspondent pas.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-success"
                        isOpen={this.state.displayConfirmModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point d'ajouter un laveur.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.addUser()}
                            >
                                Ajouter
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/laveurs" />}
                </Container >
            </>
        );
    }

    addUser = async () => {
        try {
            const URL = '/pro/registerWithCredentials';
            this.setState({ displayConfirmModal: false });

            if (this.checkInputs()) {
                let response = await utils.apiRequest.send(URL, 'PUT', this.state.newUser);

                if (response.result) {
                    utils.alert.notify('success', 'Le compte a été créé.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000);
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            if (error.message.endsWith('409')) {
                utils.alert.notify('warning', 'Cette adresse email est déjà utilisée.', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de l\'ajout.', this.notification);
            }
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { newUser } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(newUser)) {
            switch (key) {
                case 'libelle':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'city':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'zipcode':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'address':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'email':
                    isError[key] = !EmailValidator.validate(this.state.newUser[key]);
                    break;
                case 'phone':
                    isError[key] = value?.length > 0 ? (value.length !== 10 || isNaN(value)) : false;
                    break;
                case 'password':
                    isError[key] = (this.state.newUser.password !== this.state.newUser.passwordRepeat || value == null);
                    break;
                case 'passwordRepeat':
                    isError[key] = (this.state.newUser.password !== this.state.newUser.passwordRepeat || value == null);
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
