import React from "react";
import {
	Card,
	CardHeader,
	Container,
	Row,
} from "reactstrap";
import utils from "../../../utils/index";
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";

export default class Coupons extends React.Component {
	state = {
		entries: [],
		loading: true,
		filters: {
			'libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'utilisateur.firstname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'utilisateur.lastname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'professionnel.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
		}
	}

	async componentDidMount() {
		const response = await utils.apiRequest.send('/coupons');
		if (response.result) {
			this.setState({ entries: response.result });
		}
		this.setState({ loading: false });
	}

	render() {
		const { entries, filters, loading } = this.state;

		return (
			<>
				<SimpleHeader name="Coupons" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Coupons</h3>
										<p className="text-muted">
											Liste des coupons de fidélité générés via l'application Bolhero
										</p>
									</div>
								</CardHeader>

								<DataTable value={entries} filters={filters}
									paginator className="p-datatable-customers" stripedRows rows={20} size="small"
									dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="Aucun élément">
									<Column field="libelle" header="Libellé" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} />
									<Column field="pointsRequis" header="Points" sortable style={{ minWidth: '4rem' }} />
									<Column field="createdAt" header="Généré le" dataType="date" sortable
										style={{ minWidth: '10rem' }}
										body={value => moment(value.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')} />
									<Column field="dateUtilisation" header="Utilisé le" sortable style={{ minWidth: '10rem' }}
										body={value => value.dateUtilisation ? moment(value.dateUtilisation).format('DD/MM/YYYY à HH[h]mm') : "Pas utilisé"} />
									<Column field="professionnel.libelle" header="Artisan" sortable filter filterPlaceholder="Saisissez..." body={value => value?.professionnel?.libelle} />
									<Column field="utilisateur.firstname" header="Prénom" sortable filter filterPlaceholder="Saisissez..." body={value => value?.utilisateur?.firstname} />
									<Column field="utilisateur.lastname" header="Nom" sortable filter filterPlaceholder="Saisissez..." body={value => value?.utilisateur?.lastname?.toUpperCase() ?? "-"} />
								</DataTable>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}
