
import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import UserAvatar from 'react-user-avatar';
import utils from '../../utils';
import { Redirect } from 'react-router-dom';

function CardsHeader({ name, parentName, statistics }) {
  const [redirect, setRedirect] = useState(false);

  const LogoutHandler = async () => {
    await utils.auth.logout();
    setRedirect(true);
  }

  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem style={{ color: 'white' }}>
                    <i className="fas fa-home" />
                  </BreadcrumbItem>
                  <BreadcrumbItem style={{ color: 'white' }}>
                    {parentName}
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col>
                <Nav className="align-items-end ml-auto ml-md-0" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="nav-link pr-0" color="" tag="a" style={{ cursor: 'pointer' }}>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <UserAvatar size="38" name={sessionStorage.getItem('fullname') || "John Doe"} color="#8F8F8F" />
                        </span>
                        <Media className="ml-2 d-none d-lg-block" onMouseOver={({ target }) => target.style.color = '#DBDBDB'} onMouseOut={({ target }) => target.style.color = 'white'}>
                          <span className="mb-0 pt-3 pb-3 text-sm font-weight-bold" style={{ color: 'white' }}>
                            {sessionStorage.getItem('fullname')}
                          </span>
                        </Media>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow m-0">Bienvenue!</h6>
                      </DropdownItem>
                      {/* <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-single-02" />
                        <span>Mon profil</span>
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-settings-gear-65" />
                        <span>Paramètres</span>
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-calendar-grid-58" />
                        <span>Activités</span>
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-support-16" />
                        <span>Support</span>
                      </DropdownItem> */}
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={async () => LogoutHandler()}
                      >
                        <i className="ni ni-user-run" />
                        <span>Déconnexion</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Consignes utilisées ce mois
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {Object.keys(statistics).length > 0 ? statistics?.nombreCyclesConsignesCeMois?.toLocaleString() : <h4>Chargement...</h4>}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Commandes ce mois
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {Object.keys(statistics).length > 0 ? statistics?.commandesCeMois?.toLocaleString() : <h4>Chargement...</h4>}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-delivery-fast" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Consignes HS ce mois
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {Object.keys(statistics).length > 0 ? statistics?.consignesHsCeMois?.toLocaleString() : <h4>Chargement...</h4>}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="fas fa-trash" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Utilisateurs au total
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {Object.keys(statistics).length > 0 ? statistics?.totalUtilisateurs?.toLocaleString() : <h4>Chargement...</h4>}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-single-02" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {redirect && <Redirect to={{ pathname: '/auth' }} />}
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
