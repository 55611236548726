
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
    Table,
    Media,
    Form
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";
import utils from "../../../utils/";

export default class OrderEdit extends React.Component {
    state = {
        order: this.props.location.state.order,
        updatedOrder: {
            commentaire: this.props.location.state.order?.commentaire,
            livreurId: this.props.location.state.order?.livreurId
        },
        displayDeleteModal: false,
        displayEditContenuModal: false,
        redirect: false,
        produits: [],
        livreurs: [],
        selectedProductToAdd: null,
        selectedContenuToEdit: null,
        amountToAdd: 1,
        isError: {
            amountToAdd: false,
            quantitePreparee: false,
            quantiteCommandee: false,
            selectedProductToAdd: false,
            commentaire: false
        }
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        await this.getContenuCommande();
        const produits = await utils.getProduitsNbCaissesDropdown();
        const livreurs = await utils.getProDropdown('isLivreur');
        this.setState({ produits: produits, livreurs: livreurs });
    }

    async getContenuCommande() {
        let response = await utils.apiRequest.send(`/commandeProduit/${this.state.order.id}`);
        this.setState({ order: response.result });
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Commandes" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Commande #{this.state.order.id} ({this.state.order?.receptionnee ? 'Livrée' : (this.state.order?.preparee ? 'Envoyée' : 'En préparation')})</h3>
                            </div>
                            <div className="float-right">
                                {!this.state.order?.preparee &&
                                    <span>
                                        <Button color="warning" size="sm" type="button" id="sendButton"
                                            onClick={() => this.update(true)}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-truck-loading" />
                                            </span>
                                            Envoyer
                                        </Button>
                                        <UncontrolledTooltip delay={0} target="sendButton">
                                            Passer la commande au statut envoyé
                                        </UncontrolledTooltip>
                                        <Button color="success" size="sm" type="button" id="saveButton"
                                            onClick={() => this.update()}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-check" />
                                            </span>
                                            Sauvegarder
                                        </Button>
                                        <UncontrolledTooltip delay={0} target="saveButton">
                                            Sauvegarder la commande
                                        </UncontrolledTooltip>
                                        <Button color="danger" size="sm" type="button"
                                            id="deleteButton"
                                            onClick={() => this.setState({ displayDeleteModal: true })}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-trash" />
                                            </span>
                                            Supprimer
                                        </Button>
                                        <UncontrolledTooltip delay={0} target="deleteButton">
                                            Supprimer la commande
                                        </UncontrolledTooltip>
                                    </span>}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Card className="mb-5">
                                <CardHeader>
                                    <div className="float-left">
                                        <h4 className="mb-0">Professionnel</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Libelle</Label>
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.order?.professionnel?.libelle}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Mail</Label>
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.order?.professionnel?.email}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Téléphone</Label>
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.order?.professionnel?.phone ?? "Aucun"}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Livreur</Label>
                                                <Input
                                                    type="select"
                                                    value={this.state.order?.livreurId}
                                                    disabled={this.state.order.preparee}
                                                    onChange={element => this.setState({
                                                        updatedOrder: {
                                                            ...this.state.updatedOrder,
                                                            livreurId: element.target.value
                                                        }
                                                    })}>
                                                    {this.state.livreurs?.map((type, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {type}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="8">
                                            <FormGroup>
                                                <Label>Commentaire</Label>
                                                <Input
                                                    rows="2"
                                                    type="textarea"
                                                    className={this.state.isError.commentaire ? "is-invalid" : ""}
                                                    onChange={element => this.setState({
                                                        updatedOrder: {
                                                            ...this.state.updatedOrder,
                                                            commentaire: element.target.value
                                                        }
                                                    })}
                                                    disabled={this.state.order?.preparee}
                                                    defaultValue={this.state.order?.commentaire}
                                                />
                                                <div className="invalid-feedback">
                                                    Le texte est incorrect.
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h4 className="mb-0">Contenu de la commande</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {!this.state.order.preparee && <Row className="mt-1 mb-3">
                                        <Col md="7">
                                            <FormGroup>
                                                <Label>Produit</Label>
                                                <Input type="select"
                                                    onChange={element => this.setState({ selectedProductToAdd: element.target.value })}
                                                    className={this.state.isError.selectedProductToAdd ? "is-invalid" : ""}
                                                    disabled={this.state.order.preparee}
                                                >
                                                    {this.state.produits.map((type, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {type}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Input>
                                                <div className="invalid-feedback">
                                                    Veuillez sélectionner un produit.
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Quantité</Label>
                                                <Input
                                                    id="quantite"
                                                    type="number"
                                                    defaultValue={this.state.amountToAdd}
                                                    onChange={element => this.setState({ amountToAdd: element.target.value })}
                                                    className={this.state.isError.amountToAdd ? "is-invalid" : ""}
                                                    disabled={this.state.order.preparee}
                                                />
                                                <div className="invalid-feedback">
                                                    La quantité est incorrecte.
                                                </div>
                                                <UncontrolledTooltip delay={0} target="quantite">
                                                    Nombre de caisse
                                                </UncontrolledTooltip>
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <Button
                                                id="addButton"
                                                className="btn-success btn-sm float-right"
                                                type="button"
                                                onClick={() => this.addContenuCommande()}
                                                disabled={this.state.order.preparee}
                                            >
                                                Ajouter
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="addButton">
                                                Ajouter {this.state.amountToAdd} caisse{this.state.amountToAdd > 1 ? 's' : ''} à la commande
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>}
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">
                                                    Produit
                                                </th>
                                                <th scope="col">
                                                    Quantité commandée
                                                </th>
                                                <th scope="col">
                                                    Quantité préparée
                                                </th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody className="list">
                                            {this.state.order?.contenu?.map((contenu, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <Media className="align-items-center">
                                                                <a
                                                                    className="avatar rounded-circle mr-3"
                                                                    href="#pablo"
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <img alt="Produit" src={`${config.api.endpoint}/produit/image/${contenu?.produit?.id}`}
                                                                    />
                                                                </a>
                                                                <Media>
                                                                    <span className="name mb-0 text-sm">
                                                                        {contenu.produit?.libelle} {contenu.produit?.isDemo ? "(démo)" : ""}
                                                                    </span>
                                                                </Media>
                                                            </Media>
                                                        </th>
                                                        <td>
                                                            {contenu?.quantiteCommandee} caisse{contenu.quantiteCommandee > 1 ? 's' : ''} ({contenu?.produit?.nbParCaisse * contenu?.quantiteCommandee} unités)
                                                        </td>
                                                        <td>
                                                            {utils.pluriel('caisse', contenu?.quantitePreparee + contenu?.quantiteAjusteeLivreur)} ({utils.pluriel('unité', contenu?.produit?.nbParCaisse * contenu?.quantitePreparee)}) {contenu?.quantiteAjusteeLivreur > 0 ? `(dont ${utils.pluriel('caisse', contenu.quantiteAjusteeLivreur)} ${utils.pluriel('perdue', contenu.quantiteAjusteeLivreur, false)} par le livreur)` : ''}
                                                        </td>
                                                        <td className="text-right">
                                                            {!this.state.order.preparee &&
                                                                <Button
                                                                    className="btn-sm"
                                                                    color="warning"
                                                                    type="button"
                                                                    onClick={() => this.setState({ displayEditContenuModal: true, selectedContenuToEdit: contenu })}
                                                                    disabled={this.state.order.preparee}>
                                                                    <span className="btn-inner--icon">
                                                                        <i className="fas fa-edit" />
                                                                    </span>
                                                                </Button>}
                                                            {/* {!this.state.order.preparee &&
                                                                <Button
                                                                    className="btn-sm"
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => this.deleteContenuCommande(contenu?.produit?.id)}
                                                                    disabled={this.state.order.preparee}>
                                                                    <span className="btn-inner--icon">
                                                                        <i className="fas fa-trash" />
                                                                    </span>
                                                                </Button>} */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>

                            <Row>
                                <Col>
                                    <h5 className="text-muted">Créée le {moment(this.state.order?.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                </Col>
                            </Row>
                            {this.state.order?.updatedAt !== this.state.order?.createdAt &&
                                <Row>
                                    <Col>
                                        <h5 className="text-muted">Mise à jour le {moment(this.state.order?.updatedAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                    </Col>
                                </Row>}
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-danger"
                        isOpen={this.state.displayDeleteModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Confirmation
                                </h4>
                                <p>
                                    Voulez vous supprimer cette commande ?
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.deleteCommande()}
                            >
                                Supprimer
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    <Modal
                        className="modal-dialog-centered"
                        size="sm"
                        isOpen={this.state.displayEditContenuModal}
                    >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    {/* <div className="text-center text-muted mb-4">
                                        <h3>Modifier le détail de la commande</h3>
                                    </div> */}
                                    <Form role="form">
                                        {/* <FormGroup>
                                            <Label>Quantité commandée</Label>
                                            <Input
                                                placeholder="ex: 5"
                                                type="text"
                                                className={this.state.isError.quantiteCommandee ? "is-invalid" : ""}
                                                defaultValue={this.state.selectedContenuToEdit?.quantiteCommandee}
                                                onChange={element => this.setState({
                                                    selectedContenuToEdit: {
                                                        ...this.state.selectedContenuToEdit,
                                                        quantiteCommandee: element.target.value
                                                    }
                                                })}
                                            />
                                            <div className="invalid-feedback">
                                                La quantité est incorrecte.
                                            </div>
                                        </FormGroup> */}
                                        <FormGroup>
                                            <Label>Quantité préparée</Label>
                                            <Input
                                                placeholder="ex: 5"
                                                type="text"
                                                className={this.state.isError.quantitePreparee ? "is-invalid" : ""}
                                                defaultValue={this.state.selectedContenuToEdit?.quantitePreparee}
                                                onChange={element => this.setState({
                                                    selectedContenuToEdit: {
                                                        ...this.state.selectedContenuToEdit,
                                                        quantitePreparee: element.target.value
                                                    }
                                                })}
                                            />
                                            <div className="invalid-feedback">
                                                La quantité est incorrecte.
                                            </div>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button
                                                className="my-4"
                                                color="success"
                                                type="button"
                                                onClick={() => this.editContenuCommande()}
                                            >
                                                Valider
                                            </Button>
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => this.setState({ displayEditContenuModal: false })}
                                            >
                                                Fermer
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Modal>

                    {this.state.redirect && <Redirect to="/admin/orders" />}
                </Container>
            </>
        );
    }

    deleteCommande = async () => {
        try {
            const URL = `/commandeProduit/delete/${this.state.order.id}`;
            this.setState({ displayDeleteModal: false });

            let response = await utils.apiRequest.send(URL, 'DELETE');

            if (response.result) {
                utils.alert.notify('success', 'La commande a été supprimée.', this.notification);
                setTimeout(() => {
                    this.setState({ redirect: true })
                }, 1000)
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la suppression.', this.notification);
            }
        }
    }

    async addContenuCommande() {
        try {
            let { amountToAdd, selectedProductToAdd, isError } = this.state;
            let isAmountCorrect = /^\d*\.?\d+$/.test(amountToAdd) && amountToAdd >= 0;

            if (isAmountCorrect && selectedProductToAdd) {
                const URL = `/commandeProduit/addContenu/${this.state.order.id}`;
                let response = await utils.apiRequest.send(URL, 'PUT', {
                    produitId: selectedProductToAdd,
                    quantitePreparee: amountToAdd
                });

                if (response.result) {
                    await this.getContenuCommande();
                } else {
                    throw new Error();
                }
            }

            this.setState({ isError: { ...isError, amountToAdd: !isAmountCorrect, selectedProductToAdd: !selectedProductToAdd } });
        } catch (error) {
            utils.alert.notify('danger', 'Il y a eu une erreur lors de l\'ajout.', this.notification);
        }
    }

    async editContenuCommande() {
        try {
            let { selectedContenuToEdit, isError } = this.state;
            let isQuantitePrepareeCorrect = /^\d*\.?\d+$/.test(selectedContenuToEdit?.quantitePreparee) && selectedContenuToEdit?.quantitePreparee >= 0;
            let isQuantiteCommandeeCorrect = /^\d*\.?\d+$/.test(selectedContenuToEdit?.quantiteCommandee) && selectedContenuToEdit?.quantiteCommandee > 0;

            if (isQuantitePrepareeCorrect && isQuantiteCommandeeCorrect) {
                const URL = `/commandeProduit/editContenu/${this.state.order.id}`;
                let response = await utils.apiRequest.send(
                    URL, 'POST', {
                    produitId: selectedContenuToEdit?.produit?.id,
                    quantitePreparee: selectedContenuToEdit?.quantitePreparee
                });

                if (response.result) {
                    await this.getContenuCommande();
                } else {
                    throw new Error();
                }
            }

            this.setState({ displayEditContenuModal: false, isError: { ...isError, quantitePreparee: !isQuantitePrepareeCorrect, quantiteCommandee: !isQuantiteCommandeeCorrect } });
        } catch (error) {
            utils.alert.notify('danger', 'Il y a eu une erreur lors de la modification.', this.notification);
        }
    }

    update = async (envoyerCommande = false) => {
        try {
            const { updatedOrder } = this.state;
            const URL = `/commandeProduit/update/${this.state.order.id}`;

            if (envoyerCommande && (updatedOrder?.livreurId?.length === 0 || updatedOrder?.livreurId?.length === undefined)) {
                utils.alert.notify('warning', 'Veuillez sélectionner un livreur', this.notification);
            } else {
                let response = await utils.apiRequest.send(URL, 'POST', { preparee: envoyerCommande, commentaire: updatedOrder.commentaire, livreurId: updatedOrder.livreurId });

                if (response.result) {
                    utils.alert.notify('success', 'La commande a été mise à jour.', this.notification);
                    await this.getContenuCommande();
                } else {
                    throw new Error();
                }
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la mise à jour.', this.notification);
            }
        }
    }
}
