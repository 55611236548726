
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Input,
	Container,
	Row,
	Col,
	Label,
	Button,
	Modal,
	UncontrolledTooltip,
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";
import utils from "../../../utils";

export default class ArtisanEdit extends React.Component {
	state = {
		user: this.props.location.state.user,
		displayDeleteModal: false,
		displayUpdateModal: false,
		displayUploadDocumentModal: false,
		redirect: false,
		updatedUser: {
			libelle: this.props.location.state.user.libelle,
			city: this.props.location.state.user.city,
			address: this.props.location.state.user.address,
			zipcode: this.props.location.state.user.zipcode,
			phone: this.props.location.state.user.phone,
			aEmporter: this.props.location.state.user.aEmporter,
			surPlace: this.props.location.state.user.surPlace,
			clickAndCollect: this.props.location.state.user.clickAndCollect,
			horairesProfessionnels: this.props.location.state.user.horairesProfessionnels,
			programmeFidelite: this.props.location.state.user.programmeFidelite,
			fideliteProfessionnels: this.props.location.state.user.fideliteProfessionnels
		},
		isError: {
			libelle: false,
			phone: false,
			city: false,
			address: false,
			zipcode: false,
			horairesProfessionnels: {
				1: false,
				2: false,
				3: false,
				4: false,
				5: false,
				6: false,
				7: false
			}
		}
	}

	constructor(props) {
		super(props);
		this.notification = React.createRef();
	}

	render() {
		return (
			<>
				<div className="rna-wrapper">
					<NotificationAlert ref={ref => this.notification = ref} />
				</div>
				<SimpleHeader name="Artisans" category="Utilisateurs" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Card className="mb-4">
						<CardHeader>
							<div className="float-left">
								<h3 className="mb-0">{this.state.user.libelle}</h3>
							</div>
							<div className="float-right">
								<Button color="success" size="sm" type="button"
									id="updateButton"
									onClick={() => this.setState({ displayUpdateModal: true })}>
									<span className="btn-inner--icon mr-1">
										<i className="fas fa-user-edit" />
									</span>
									Mettre à jour
								</Button>
								<UncontrolledTooltip delay={0} target="updateButton">
									Enregistrer les modifications
								</UncontrolledTooltip>
								<Button color="danger" size="sm" type="button"
									id="deleteButton"
									onClick={() => this.setState({ displayDeleteModal: true })}>
									<span className="btn-inner--icon mr-1">
										<i className="fas fa-trash" />
									</span>
									Supprimer
								</Button>
								<UncontrolledTooltip delay={0} target="deleteButton">
									Supprimer le compte de l'artisan
								</UncontrolledTooltip>
							</div>
						</CardHeader>
						<CardBody>
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Informations</h3>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<FormGroup>
												<Label>Nom de l'établissement</Label>
												<Input
													id="libelle"
													placeholder="Libellé"
													type="text"
													className={this.state.isError.libelle ? "is-invalid" : ""}
													defaultValue={this.state.user.libelle}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															libelle: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Le libellé est incorrect.
												</div>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label>Adresse e-mail</Label>
												<Input
													id="email"
													placeholder="E-mail"
													type="email"
													className={this.state.isError.email ? "is-invalid" : ""}
													defaultValue={this.state.user.email}
													disabled
												/>
												<div className="invalid-feedback">
													L'adresse e-mail est incorrecte.
												</div>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label>Téléphone</Label>
												<Input
													id="phone"
													placeholder="Téléphone"
													type="tel"
													maxLength={10}
													defaultValue={this.state.user.phone}
													className={this.state.isError.phone ? "is-invalid" : ""}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															phone: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Le téléphone est incorrect.
												</div>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormGroup>
												<Label>Adresse</Label>
												<Input
													id="address"
													placeholder="Adresse"
													type="text"
													defaultValue={this.state.user.address}
													className={this.state.isError.address ? "is-invalid" : ""}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															address: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													L'adresse est incorrecte.
												</div>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label>Ville</Label>
												<Input
													id="city"
													placeholder="Ville"
													type="text"
													defaultValue={this.state.user.city}
													className={this.state.isError.city ? "is-invalid" : ""}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															city: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													La ville est incorrecte.
												</div>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label>Code postal</Label>
												<Input
													id="zipcode"
													placeholder="Code postal"
													type="text"
													maxLength={5}
													defaultValue={this.state.user.zipcode}
													className={this.state.isError.zipcode ? "is-invalid" : ""}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															zipcode: element.target.value
														}
													})}
												/>
												<div className="invalid-feedback">
													Le code postal est incorrect.
												</div>
											</FormGroup>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Services</h3>
										<p className="text-muted mb-0">
											Les services proposés par l'artisan. Ils s'afficheront sur la carte de l'application Bolhero.
										</p>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxIsAEmporter"
													type="checkbox"
													defaultChecked={this.state.updatedUser.aEmporter}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															aEmporter: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxIsAEmporter"
													id="aEmporter"
												>
													A emporter
												</Label>
											</div>
										</Col>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxSurplace"
													type="checkbox"
													defaultChecked={this.state.updatedUser.surPlace}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															surPlace: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxSurplace"
													id="surPlace"
												>
													Sur place
												</Label>
											</div>
										</Col>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxClickAndCollect"
													type="checkbox"
													defaultChecked={this.state.updatedUser.clickAndCollect}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															clickAndCollect: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxClickAndCollect"
													id="clickAndCollect"
												>
													Click and collect
												</Label>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Fidélité</h3>
										<p className="text-muted mb-0">
											Si activé, l'artisan pourra proposer à ses clients un programme de fidélité et ses clients gagnerons des points de fidélité à chaque attribution et retour de contenant.
										</p>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<div className="custom-control custom-checkbox mb-3">
												<input
													className="custom-control-input"
													id="checkboxProgrammeFidelite"
													type="checkbox"
													defaultChecked={this.state.updatedUser.programmeFidelite}
													onChange={element => this.setState({
														updatedUser: {
															...this.state.updatedUser,
															programmeFidelite: element.target.checked
														}
													})}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxProgrammeFidelite"
													id="programmeFidelite"
												>
													Programme de fidélité
												</Label>
											</div>
										</Col>
									</Row>
									{this.state.updatedUser.fideliteProfessionnels.length > 0 &&
										<Card>
											<CardHeader>
												<div className="float-left">
													<h3 className="mb-0">Programme de fidélité de {this.state.updatedUser.libelle}</h3>
													<p className="text-muted mb-0">
														Ce programme est défini par l'artisan, il peut choisir d'activer ou désactiver certains palier, cependant, le nombre de points requis pour chaque palier est fixe.
													</p>
												</div>
											</CardHeader>
											<CardBody>
												<Col>
													{this.state.updatedUser.fideliteProfessionnels.map((palier) => {
														return (
															<Row key={palier.id}>
																<h4>{palier.pointsRequis} points:&nbsp;</h4>
																<Label className={!palier.active ? "text-muted" : ""}>{palier.libelle} {!palier.active && "(désactivé par l'artisan)"}</Label>
															</Row>
														)
													})}

												</Col>
											</CardBody>
										</Card>
									}
								</CardBody>
							</Card>

							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Horaires</h3>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="2">
											<Label className="mt-4">Lundi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.horaireOuvertureMatin}
													className={this.state.isError.horairesProfessionnels[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureMatin', 1)}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.horaireFermetureMatin}
													className={this.state.isError.horairesProfessionnels[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureMatin', 1)}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.horaireOuvertureApresMidi}
													className={this.state.isError.horairesProfessionnels[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureApresMidi', 1)}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.horaireFermetureApresMidi}
													className={this.state.isError.horairesProfessionnels[1] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureApresMidi', 1)}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeLundi"
													type="checkbox"
													defaultChecked={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 1)?.ferme}
													onChange={element => this.handleHoraireChange(element.target.checked, 'ferme', 1)}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeLundi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Mardi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.horaireOuvertureMatin}
													className={this.state.isError.horairesProfessionnels[2] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureMatin', 2)}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.horaireFermetureMatin}
													className={this.state.isError.horairesProfessionnels[2] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureMatin', 2)}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.horaireOuvertureApresMidi}
													className={this.state.isError.horairesProfessionnels[2] ? "is-invalid" : ""}
													type="time"
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureApresMidi', 2)}
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.horaireFermetureApresMidi}
													className={this.state.isError.horairesProfessionnels[2] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureApresMidi', 2)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeMardi"
													type="checkbox"
													defaultChecked={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 2)?.ferme}
													onChange={element => this.handleHoraireChange(element.target.checked, 'ferme', 2)}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeMardi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Mercredi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.horaireOuvertureMatin}
													className={this.state.isError.horairesProfessionnels[3] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureMatin', 3)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.horaireFermetureMatin}
													className={this.state.isError.horairesProfessionnels[3] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureMatin', 3)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.horaireOuvertureApresMidi}
													className={this.state.isError.horairesProfessionnels[3] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureApresMidi', 3)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.horaireFermetureApresMidi}
													className={this.state.isError.horairesProfessionnels[3] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureApresMidi', 3)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeMercredi"
													type="checkbox"
													defaultChecked={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 3)?.ferme}
													onChange={element => this.handleHoraireChange(element.target.checked, 'ferme', 3)}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeMercredi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Jeudi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.horaireOuvertureMatin}
													className={this.state.isError.horairesProfessionnels[4] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureMatin', 4)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.horaireFermetureMatin}
													className={this.state.isError.horairesProfessionnels[4] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureMatin', 4)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.horaireOuvertureApresMidi}
													className={this.state.isError.horairesProfessionnels[4] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureApresMidi', 4)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.horaireFermetureApresMidi}
													className={this.state.isError.horairesProfessionnels[4] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureApresMidi', 4)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeJeudi"
													type="checkbox"
													defaultChecked={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 4)?.ferme}
													onChange={element => this.handleHoraireChange(element.target.checked, 'ferme', 4)}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeJeudi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Vendredi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.horaireOuvertureMatin}
													className={this.state.isError.horairesProfessionnels[5] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureMatin', 5)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.horaireFermetureMatin}
													className={this.state.isError.horairesProfessionnels[5] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureMatin', 5)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.horaireOuvertureApresMidi}
													className={this.state.isError.horairesProfessionnels[5] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureApresMidi', 5)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.horaireFermetureApresMidi}
													className={this.state.isError.horairesProfessionnels[5] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureApresMidi', 5)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeVendredi"
													type="checkbox"
													defaultChecked={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 5)?.ferme}
													onChange={element => this.handleHoraireChange(element.target.checked, 'ferme', 5)}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeVendredi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Samedi</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.horaireOuvertureMatin}
													className={this.state.isError.horairesProfessionnels[6] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureMatin', 6)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.horaireFermetureMatin}
													className={this.state.isError.horairesProfessionnels[6] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureMatin', 6)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.horaireOuvertureApresMidi}
													className={this.state.isError.horairesProfessionnels[6] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureApresMidi', 6)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.horaireFermetureApresMidi}
													className={this.state.isError.horairesProfessionnels[6] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureApresMidi', 6)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeSamedi"
													type="checkbox"
													defaultChecked={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 6)?.ferme}
													onChange={element => this.handleHoraireChange(element.target.checked, 'ferme', 6)}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeSamedi"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="2">
											<Label className="mt-4">Dimanche</Label>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.horaireOuvertureMatin}
													className={this.state.isError.horairesProfessionnels[7] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureMatin', 7)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture matin</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.horaireFermetureMatin}
													className={this.state.isError.horairesProfessionnels[7] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureMatin', 7)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Ouverture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.horaireOuvertureApresMidi}
													className={this.state.isError.horairesProfessionnels[7] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireOuvertureApresMidi', 7)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<FormGroup>
												<Label>Fermeture soir</Label>
												<Input
													disabled={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.ferme}
													defaultValue={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.horaireFermetureApresMidi}
													className={this.state.isError.horairesProfessionnels[7] ? "is-invalid" : ""}
													onChange={element => this.handleHoraireChange(element.target.value, 'horaireFermetureApresMidi', 7)}
													type="time"
												/>
												<div className="invalid-feedback">
													L'horaire est incorrect
												</div>
											</FormGroup>
										</Col>
										<Col md="2">
											<div className="custom-control custom-checkbox mt-4">
												<input
													className="custom-control-input"
													id="checkboxFermeDimanche"
													type="checkbox"
													defaultChecked={this.state.updatedUser.horairesProfessionnels.find(e => e.jourSemaine === 7)?.ferme}
													onChange={element => this.handleHoraireChange(element.target.checked, 'ferme', 7)}
												/>
												<Label
													className="custom-control-label"
													htmlFor="checkboxFermeDimanche"
													id="ferme"
												>
													Fermé
												</Label>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Row>
								<Col>
									<h5 className="text-muted">Inscrit le {moment(this.state.user.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
								</Col>
							</Row>
							{this.state.user.updatedAt !== this.state.user.createdAt &&
								<Row>
									<Col>
										<h5 className="text-muted">Mis à jour le {moment(this.state.user.updatedAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
									</Col>
								</Row>}
						</CardBody>
					</Card>
					<Modal
						className="modal-dialog-centered modal-danger"
						contentClassName="bg-gradient-danger"
						isOpen={this.state.displayDeleteModal}
					>
						<div className="modal-header">
							<h6
								className="modal-title"
								id="modal-title-notification"
							>
								Confirmation
							</h6>
							<button
								aria-label="Close"
								className="close"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayDeleteModal: false })}
							>
								<span aria-hidden={true}>×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="py-3 text-center">
								<i className="ni ni-bell-55 ni-3x" />
								<h4 className="heading mt-4">
									Attention!
								</h4>
								<p>
									Vous êtes sur le point du supprimer le compte professionnel de {this.state.user?.libelle ?? this.state.user?.firstname + ' ' + this.state.user?.lastname?.toUpperCase()},
									cette action est irréversible.
								</p>
							</div>
						</div>
						<div className="modal-footer">
							<Button
								className="btn-white"
								color="default"
								type="button"
								onClick={() => this.deleteUser()}
							>
								Supprimer
							</Button>
							<Button
								className="text-white ml-auto"
								color="link"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayDeleteModal: false })}
							>
								Annuler
							</Button>
						</div>
					</Modal>

					<Modal
						className="modal-dialog-centered modal-danger"
						contentClassName="bg-gradient-info"
						isOpen={this.state.displayUpdateModal}
					>
						<div className="modal-header">
							<h6
								className="modal-title"
								id="modal-title-notification"
							>
								Confirmation
							</h6>
							<button
								aria-label="Close"
								className="close"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayUpdateModal: false })}
							>
								<span aria-hidden={true}>×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="py-3 text-center">
								<i className="ni ni-bell-55 ni-3x" />
								<h4 className="heading mt-4">
									Attention!
								</h4>
								<p>
									Vous êtes sur le point de mettre à jour le compte professionnel de {this.state.user?.libelle ?? this.state.user?.firstname + ' ' + this.state.user?.lastname?.toUpperCase()},
									cette action est irréversible.
								</p>
							</div>
						</div>
						<div className="modal-footer">
							<Button
								className="btn-white"
								color="default"
								type="button"
								onClick={() => this.updateUser()}
							>
								Mettre à jour
							</Button>
							<Button
								className="text-white ml-auto"
								color="link"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayUpdateModal: false })}
							>
								Annuler
							</Button>
						</div>
					</Modal>
					{this.state.redirect && <Redirect to="/admin/artisans" />}
				</Container>
			</>
		);
	}

	deleteUser = async () => {
		try {
			const URL = `/pro/delete/${this.state.user.id}`;
			this.setState({ displayDeleteModal: false });

			let response = await utils.apiRequest.send(URL, 'DELETE');

			if (response) {
				if (response.result) {
					utils.alert.notify('success', 'Le compte a été supprimé.', this.notification);
					setTimeout(() => {
						this.setState({ redirect: true })
					}, 1000)
				} else {
					utils.alert.notify('danger', response.error, this.notification);
				}
			} else {
				throw new Error();
			}
		} catch (error) {
			if (error.message.endsWith('403')) {
				utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
			} else {
				utils.alert.notify('danger', 'Il y a eu une erreur lors de la suppression.', this.notification);
			}
		}
	}

	updateUser = async () => {
		try {
			const URL = `/pro/update/${this.state.user.id}`;
			this.setState({ displayUpdateModal: false });

			if (this.checkInputs()) {
				let response = await utils.apiRequest.send(URL, 'POST', this.state.updatedUser);

				if (response.result) {
					utils.alert.notify('success', 'Le compte professionnel a été mis à jour.', this.notification);
					setTimeout(() => {
						this.setState({ redirect: true })
					}, 1000)
				} else {
					throw new Error();
				}
			} else {
				utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
			}
		} catch (error) {
			if (error.message.endsWith('403')) {
				utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
			} else {
				utils.alert.notify('danger', 'Il y a eu une erreur lors de la mise à jour.', this.notification);
			}
		}
	}

	handleHoraireChange = (value, property, day) => {
		try {
			let horairesProfessionnels = this.state.updatedUser.horairesProfessionnels;
			let itemIndex = horairesProfessionnels.findIndex(e => e.jourSemaine === day);

			if (itemIndex !== -1) {
				horairesProfessionnels[itemIndex][property] = value;
			} else {
				horairesProfessionnels.push({
					jourSemaine: day,
					[property]: value
				});
			}

			this.setState(prevState => ({
				...prevState,
				updatedUser: {
					...prevState.updatedUser,
					horairesProfessionnels: horairesProfessionnels
				}
			}));
		} catch (error) { }
	}

	checkInputs = () => {
		let { isError } = this.state;
		let { updatedUser } = this.state;
		let areInputsCorrect = true;

		for (const [key, value] of Object.entries(updatedUser)) {
			switch (key) {
				case 'libelle':
					isError[key] = value == null || value.length < 2;
					break;
				case 'phone':
					isError[key] = value?.length > 0 ? (value.length !== 10 || isNaN(value)) : false;
					break;
				case 'city':
					isError[key] = value == null || value.length < 2;
					break;
				case 'zipcode':
					isError[key] = value == null || value.length !== 5 || isNaN(value);
					break;
				case 'address':
					isError[key] = value == null || value.length < 2;
					break;
				case 'horairesProfessionnels':
					for (let jour = 0; jour < value.length; jour++) {
						const horaire = value[jour];
						isError[key][horaire.jourSemaine] = !horaire.ferme && (horaire.horaireOuvertureMatin == null || horaire.horaireFermetureMatin == null || horaire.horaireOuvertureApresMidi == null || horaire.horaireFermetureApresMidi == null);
					}
					break;
				default:
					break;
			}
		}
		this.setState({ isError });

		// Retourne false si un des champs est incorrect
		for (const [key, value] of Object.entries(isError)) {
			if (typeof value === 'object') {
				if (Object.values(value).some(v => v === true)) {
					areInputsCorrect = false;
				}
			} else if (value === true) {
				areInputsCorrect = false;
			}
			if (key) { }
		}

		return areInputsCorrect;
	}
}
