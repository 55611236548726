import React from "react";
import {
	Card,
	CardHeader,
	Container,
	Row,
} from "reactstrap";
import utils from "../../utils/index";
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Column as PrimeColumn } from 'primereact/column';
import { Row as PrimeRow } from 'primereact/row';
import config from "../../config";

export default class Cycles extends React.Component {
	state = {
		entries: [],
		loading: true,
		filters: {
			'numeroAAfficher': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'consigne.produit.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'fullname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'artisan.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'renduArtisan.libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'laveur': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
		}
	}

	async componentDidMount() {
		const response = await utils.apiRequest.send('/cycle');
		if (response.result) {
			this.setState({ entries: response.result, delaiRetourConsigne: response.delaiRetourConsigne });
		}
		this.setState({ loading: false });
	}

	headerGroup = (
		<PrimeColumn>
			<PrimeRow>
				<PrimeColumn header="Contenant" colSpan={2} />
				<PrimeColumn header="Client" colSpan={1} />
				<PrimeColumn header="Début" colSpan={2} />
				<PrimeColumn header="Fin" colSpan={2} />
				<PrimeColumn header="Lavage" colSpan={1} />
				<PrimeColumn header="Paiement" colSpan={3} />
			</PrimeRow>
			<PrimeRow>
				<PrimeColumn header="Consigne" field="numeroAAfficher" filter filterPlaceholder="Saisissez..." />
				<PrimeColumn header="Produit" field="consigne.produit.libelle" sortable filter filterPlaceholder="Saisissez..." />

				<PrimeColumn header="Utilisateur" field="fullname" sortable filter filterPlaceholder="Saisissez..." />

				<PrimeColumn header="Récupérée le" field="depotUtilisateurLe" dataType="date" sortable style={{ minWidth: '11rem' }} />
				<PrimeColumn header="Prise chez" field="artisan.libelle" sortable filter filterPlaceholder="Saisissez..." />

				<PrimeColumn header="Rendue le" field="renduLe" dataType="date" sortable style={{ minWidth: '11rem' }} />
				<PrimeColumn header="Rendue chez" field="renduArtisan.libelle" sortable filter filterPlaceholder="Saisissez..." />

				<PrimeColumn header="Laveur" field="laveur" sortable filter filterPlaceholder="Saisissez..." />

				<PrimeColumn header="Payée" field="estPaye" sortable />
				<PrimeColumn header="Échec paiement" field="nbrTentativeDePaiement" sortable />
				<PrimeColumn header="Jours de retard" />
			</PrimeRow>
		</PrimeColumn>
	)

	render() {
		const { entries, filters, delaiRetourConsigne, loading } = this.state;

		return (
			<>
				<SimpleHeader name="Cycles" category="Produits" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Cycles</h3>
										<p className="text-muted">
											Liste des cycles des consignes.
										</p>
									</div>
								</CardHeader>

								<DataTable value={entries} filters={filters} headerColumnGroup={this.headerGroup}
									paginator className="p-datatable-customers" stripedRows rows={20} size="small"
									dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="Aucun élément">
									<Column field="numeroAAfficher" />
									<Column field="consigne.produit.libelle" />
									<Column field="fullname" body={value => value.fullname} />
									<Column field="depotUtilisateurLe" body={value => moment(value.depotUtilisateurLe).locale('fr').format('DD/MM/YYYY à HH[h]mm')} />
									<Column field="artisan.libelle" body={value => value?.artisan?.libelle} />
									<Column field="renduLe" body={value => value.renduLe && moment(value.renduLe).locale('fr').format('DD/MM/YYYY à HH[h]mm')} />
									<Column field="renduArtisan.libelle" body={value => value?.renduArtisan?.libelle ?? ""} />
									<Column field="laveur" header="Laveur" body={value => value?.laveur ?? ""} />
									<Column field="estPaye" body={value => value?.estPaye ? <div className="text-success">Oui</div> : moment(value?.depotUtilisateurLe).add(delaiRetourConsigne, 'days').isAfter() ? "" : <div className="text-danger">Non</div>} />
									<Column field="nbrTentativeDePaiement" body={value => value.nbrTentativeDePaiement > 0 ? "Oui" : ""} />
									<Column body={value => value.estPaye ? '' : value.joursDeRetard} />
								</DataTable>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}
