
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap";
import { Redirect } from "react-router";
import utils from '../../../utils';
import config from '../../../config';
import AuthHeader from "components/Headers/AuthHeader.js";

export default class Login extends React.Component {
  state = {
    email: null,
    password: null,
    apiError: false,
    redirect: false
  }

  login = async () => {
    if (await utils.auth.login(this.state.email, this.state.password)) {
      this.setState({ redirect: true });
    } else {
      this.setState({ apiError: true });
    }
  }

  render() {
    return (
      <>
        <AuthHeader
          title={config.app.name}
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              {this.state.apiError &&
                <UncontrolledAlert color="danger">
                  <span className="alert-icon">
                    <i className="ni ni-notification-70" />
                  </span>
                  <span className="alert-text ml-1">
                    <strong>Erreur: </strong>
                      Identifiants incorrects
                  </span>
                </UncontrolledAlert>}
              <Card className="bg-secondary border-0 mb-0">
                {/* <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small>Sign in with</small>
                </div>
                <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div>
              </CardHeader> */}
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Connexion</small>
                  </div>
                  <Form role="form" onSubmit={(e) => e.preventDefault()}>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="E-mail"
                          type="email"
                          onChange={element => this.setState({ email: element.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Mot de passe"
                          type="password"
                          onChange={element => this.setState({ password: element.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="submit"
                        onClick={() => this.login()}>
                        Se connecter
                    </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                {/* <Col xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <small>Mot de passe oublié?</small>
                  </a>
                </Col> */}
                {/* <Col className="text-right" xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <small>Inscription</small>
                  </a>
                </Col> */}
              </Row>
            </Col>
          </Row>
          {this.state.redirect && <Redirect to="/admin" />}
        </Container>
      </>
    );
  }
}
