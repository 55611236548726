
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
    Table,
    Media,
    Form
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";
import utils from "../../../utils/";

export default class OrderAdd extends React.Component {
    state = {
        professionnelId: null,
        livreurId: null,
        commentaire: null,
        displayEditContenuModal: false,
        redirect: false,
        produits: [],
        contenuCommande: [],
        professionnels: [],
        livreurs: [],
        selectedProductToAdd: null,
        selectedContenuToEdit: [],
        amountToAdd: 1,
        isError: {
            amountToAdd: false,
            quantitePreparee: false,
            quantiteCommandee: false,
            selectedProductToAdd: false,
            commentaire: false
        }
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        const produits = await utils.apiRequest.send('produit?order=libelle&sort=ASC');
        const professionnels = await utils.getProDropdown('isArtisan');
        const livreurs = await utils.getProDropdown('isLivreur');
        this.setState({ produits: produits.result, professionnels: professionnels, livreurs: livreurs });
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Commandes" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Nouvelle commande</h3>
                            </div>
                            <div className="float-right">
                                {!this.state.order?.preparee &&
                                    <span>
                                        <Button color="success" size="sm" type="button"
                                            id="validateButton"
                                            onClick={() => this.addCommande()}>
                                            Créer la commande
                                        </Button>
                                    </span>}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Card className="mb-5">
                                <CardHeader>
                                    <div className="float-left">
                                        <h4 className="mb-0">Professionnel</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Professionnel</Label>
                                                <Input
                                                    type="select"
                                                    onChange={(element) => this.setState({ professionnelId: element.target.value })}>
                                                    {this.state.professionnels?.map((type, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {type}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Livreur</Label>
                                                <Input
                                                    type="select"
                                                    onChange={(element) => this.setState({ livreurId: element.target.value })}>
                                                    {this.state.livreurs?.map((type, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {type}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Créée par</Label>
                                                <Input
                                                    type="text"
                                                    defaultValue={sessionStorage.getItem('fullname')}
                                                    disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>Commentaire</Label>
                                                <Input
                                                    rows="2"
                                                    type="textarea"
                                                    className={this.state.isError.commentaire ? "is-invalid" : ""}
                                                    onChange={element => this.setState({ commentaire: element.target.value })}
                                                />
                                                <div className="invalid-feedback">
                                                    Le texte est incorrect.
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h4 className="mb-0">Contenu de la commande</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mt-1 mb-3">
                                        <Col md="7">
                                            <FormGroup>
                                                <Label>Produit</Label>
                                                <Input type="select"
                                                    onChange={element => this.setState({ selectedProductToAdd: element.target.value })}
                                                    className={this.state.isError.selectedProductToAdd ? "is-invalid" : ""}
                                                    disabled={this.state.order?.preparee}
                                                >
                                                    <option value="">{this.state.produits?.length === 0 ? "Aucune entrée" : "Sélectionnez une valeur"}</option>
                                                    {this.state.produits?.map((type, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <option value={type.id}>Caisse {type.libelle} ({type.nbParCaisse} unités)</option>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Input>
                                                <div className="invalid-feedback">
                                                    Veuillez sélectionner un produit.
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Quantité</Label>
                                                <Input
                                                    id="quantite"
                                                    type="number"
                                                    defaultValue={this.state.amountToAdd}
                                                    onChange={element => this.setState({ amountToAdd: element.target.value })}
                                                    className={this.state.isError.amountToAdd ? "is-invalid" : ""}
                                                    disabled={this.state.order?.preparee}
                                                />
                                                <div className="invalid-feedback">
                                                    La quantité est incorrecte.
                                                </div>
                                                <UncontrolledTooltip delay={0} target="quantite">
                                                    Nombre de caisse
                                                </UncontrolledTooltip>
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <Button
                                                className="btn-success btn-sm float-right"
                                                type="button"
                                                onClick={() => this.addContenuCommande()}>
                                                Ajouter
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">
                                                    Produit
                                                </th>
                                                <th scope="col">
                                                    Quantité commandée
                                                </th>
                                                <th scope="col">
                                                    Quantité préparée
                                                </th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody className="list">
                                            {this.state.contenuCommande?.map((contenu, index) => {
                                                const produit = this.state.produits.find(p => p.id === contenu.produitId);
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <Media className="align-items-center">
                                                                <a
                                                                    className="avatar rounded-circle mr-3"
                                                                    href="#pablo"
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <img alt="Produit" src={`${config.api.endpoint}/produit/image/${contenu.produitId}`}
                                                                    />
                                                                </a>
                                                                <Media>
                                                                    <span className="name mb-0 text-sm">
                                                                        {produit?.libelle} {produit?.isDemo ? "(démo)" : ""}
                                                                    </span>
                                                                </Media>
                                                            </Media>
                                                        </th>
                                                        <td>
                                                            {contenu.quantiteCommandee} caisse{contenu.quantiteCommandee > 1 ? 's' : ''} ({produit?.nbParCaisse * contenu.quantiteCommandee} unités)
                                                        </td>
                                                        <td>
                                                            {contenu.quantitePreparee} caisse{contenu.quantitePreparee > 1 ? 's' : ''}  ({produit?.nbParCaisse * contenu.quantitePreparee} unités)
                                                        </td>
                                                        <td className="text-right">
                                                            <Button
                                                                className="btn-sm"
                                                                color="warning"
                                                                type="button"
                                                                onClick={() => this.setState({ displayEditContenuModal: true, selectedContenuToEdit: contenu })}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="fas fa-edit" />
                                                                </span>
                                                            </Button>
                                                            <Button
                                                                className="btn-sm"
                                                                color="danger"
                                                                type="button"
                                                                onClick={() => this.deleteContenuCommande(contenu.produitId)}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className="fas fa-trash" />
                                                                </span>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered"
                        size="sm"
                        isOpen={this.state.displayEditContenuModal}
                    >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <h3>Modifier le détail de la commande</h3>
                                    </div>
                                    <Form role="form">
                                        <FormGroup>
                                            <Label>Quantité commandée</Label>
                                            <Input
                                                placeholder="ex: 5"
                                                type="text"
                                                className={this.state.isError.quantiteCommandee ? "is-invalid" : ""}
                                                defaultValue={this.state.selectedContenuToEdit?.quantiteCommandee}
                                                onChange={element => this.setState({
                                                    selectedContenuToEdit: {
                                                        ...this.state.selectedContenuToEdit,
                                                        quantiteCommandee: element.target.value
                                                    }
                                                })}
                                            />
                                            <div className="invalid-feedback">
                                                La quantité est incorrecte.
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Quantité préparée</Label>
                                            <Input
                                                placeholder="ex: 5"
                                                type="text"
                                                className={this.state.isError.quantitePreparee ? "is-invalid" : ""}
                                                defaultValue={this.state.selectedContenuToEdit?.quantitePreparee}
                                                onChange={element => this.setState({
                                                    selectedContenuToEdit: {
                                                        ...this.state.selectedContenuToEdit,
                                                        quantitePreparee: element.target.value
                                                    }
                                                })}
                                            />
                                            <div className="invalid-feedback">
                                                La quantité est incorrecte.
                                            </div>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button
                                                className="my-4"
                                                color="success"
                                                type="button"
                                                onClick={() => this.editContenuCommande()}
                                            >
                                                Valider
                                            </Button>
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => this.setState({ displayEditContenuModal: false })}
                                            >
                                                Fermer
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Modal>

                    {this.state.redirect && <Redirect to="/admin/orders" />}
                </Container>
            </>
        );
    }

    async addContenuCommande() {
        let { amountToAdd, selectedProductToAdd, isError, contenuCommande } = this.state;
        let isAmountCorrect = /^\d*\.?\d+$/.test(amountToAdd) && amountToAdd > 0;

        if (isAmountCorrect && selectedProductToAdd) {
            let exist = contenuCommande.find(c => c.produitId === selectedProductToAdd);
            if (exist) {
                exist.quantiteCommandee += +amountToAdd;
                exist.quantitePreparee += +amountToAdd;
                let index = contenuCommande.findIndex(c => c.produitId === selectedProductToAdd);
                contenuCommande[index] = exist;
            } else {
                contenuCommande.push({
                    produitId: selectedProductToAdd,
                    quantiteCommandee: +amountToAdd,
                    quantitePreparee: +amountToAdd
                });
            }

            this.setState({
                contenuCommande: contenuCommande
            });
        }

        this.setState({ isError: { ...isError, amountToAdd: !isAmountCorrect, selectedProductToAdd: !selectedProductToAdd } });
    }

    async editContenuCommande() {
        let { selectedContenuToEdit, contenuCommande, isError } = this.state;
        let isQuantitePrepareeCorrect = /^\d*\.?\d+$/.test(selectedContenuToEdit?.quantitePreparee) && selectedContenuToEdit?.quantitePreparee > 0;
        let isQuantiteCommandeeCorrect = /^\d*\.?\d+$/.test(selectedContenuToEdit?.quantiteCommandee) && selectedContenuToEdit?.quantiteCommandee > 0;

        if (isQuantitePrepareeCorrect && isQuantiteCommandeeCorrect) {
            let exist = contenuCommande.find(c => c.produitId === selectedContenuToEdit.produitId);
            let index = contenuCommande.findIndex(c => c.produitId === selectedContenuToEdit.produitId);
            exist.quantiteCommandee = +selectedContenuToEdit.quantiteCommandee;
            exist.quantitePreparee = +selectedContenuToEdit.quantitePreparee;
            contenuCommande[index] = exist;
            this.setState({ contenuCommande: contenuCommande });
        }

        this.setState({ displayEditContenuModal: false, isError: { ...isError, quantitePreparee: !isQuantitePrepareeCorrect, quantiteCommandee: !isQuantiteCommandeeCorrect } });
    }

    async deleteContenuCommande(produitId) {
        let { contenuCommande } = this.state;
        contenuCommande = contenuCommande.filter(c => c.produitId !== produitId);
        this.setState({ contenuCommande: contenuCommande });
    }

    addCommande = async () => {
        try {
            const URL = '/commandeProduit/add/';
            const { professionnelId, livreurId, contenuCommande, commentaire } = this.state;

            if (professionnelId && contenuCommande.length > 0) {
                let response = await utils.apiRequest.send(URL, 'PUT', {
                    professionnelId: professionnelId,
                    livreurId: livreurId,
                    contenu: contenuCommande,
                    passeeParBolhero: true,
                    commentaire: commentaire
                });

                if (response.result) {
                    utils.alert.notify('success', 'La commande a été créée.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000);
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Veuillez associer du contenu et un professionnel à la commande.', this.notification);
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la création de la commande.', this.notification);
            }
        }
    }
}
