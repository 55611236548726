import utils from './';

async function login(email, password) {
	try {
		let success = false;

		const credentials = {
			"email": email,
			"password": password
		}

		const response = await utils.apiRequest.send('/pro/signIn', 'POST', credentials);

		if (response.result) {
			const userInfos = await utils.apiRequest.send('/pro/me');
			if (userInfos.isAdmin) {
				sessionStorage.setItem('email', userInfos.email);
				sessionStorage.setItem('fullname', userInfos.firstname + ' ' + userInfos.lastname);
				sessionStorage.setItem('isLaveur', userInfos.isLaveur);
				sessionStorage.setItem('isArtisan', userInfos.isArtisan);
				sessionStorage.setItem('isAdmin', userInfos.isAdmin);

				localStorage.setItem('email', userInfos.email);
				localStorage.setItem('password', response.hash);

				success = true;
			}
		}

		return success;
	} catch (error) {
		localStorage.clear();
	}
}

async function logout() {
	localStorage.clear();
	sessionStorage.clear();
	await utils.apiRequest.send('/signOut');
}

const auth = {
	login,
	logout
}

export default auth;