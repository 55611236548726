import bolheroApiService from '../services/bolheroApiService';
import auth from './auth';

async function send(url, method, data, displayNotification) {
    return await sendWithRetry(url, method, data, displayNotification, 1);
}

async function sendWithRetry(url, method = 'GET', data, displayNotification = false, retry) {
    let result = null;

    try {
        const response = await bolheroApiService.request({
            url: url,
            method: method,
            data: data
        });

        if (response.data !== null) {
            result = response.data;
        } else {
            throw new Error();
        }
    } catch (error) {
        if (error.response.status === 401) {
            if (!url.toLowerCase().includes('signin')) {
                if (retry === 1) {
                    if (await auth.login(localStorage.getItem('email'), localStorage.getItem('password'))) {
                        return await sendWithRetry(url, method, data, displayNotification, 0);
                    } else {
                        await auth.logout();
                    }
                }
            } else {
                await auth.logout();
            }
        }
		throw new Error(error.response.status.toString());
    }

    return result;
}

const apiRequest = {
    send
}

export default apiRequest;