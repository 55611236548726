import React from "react";


// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import config from "../../../config";
import SimpleHeader from "components/Headers/SimpleHeader.js";
//import { saveAs } from 'file-saver';
import uuid from 'react-uuid';
import utils from '../../../utils';

export default class CartePrepayeeGenerate extends React.Component {
    state = {
        redirect: false,
        montantsDisponibles: [],
        newEntry: {
            quantite: 0,
            montant: 0
        },
        isError: {
            quantite: false,
            montant: false
        },
        displayConfirmModal: false
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        const montantsDisponibles = await utils.apiRequest.send('cartePrepayee/montants');
        this.setState({ montantsDisponibles: montantsDisponibles.result })
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Cartes prépayées" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Générer des cartes prépayées</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="createButton"
                                    onClick={() => this.setState({ displayConfirmModal: true })}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-qrcode" />
                                    </span>
                                    Générer
                                </Button>
                                <UncontrolledTooltip delay={0} target="createButton">
                                    Générer {this.state.newEntry.quantite} carte(s) prépayée(s)
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Quantité de cartes à générer</Label>
                                        <Input
                                            id="quantite"
                                            placeholder="ex: 50"
                                            type="number"
                                            className={this.state.isError.quantite ? "is-invalid" : ""}
                                            value={this.state.newEntry.quantite}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    quantite: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La quantité est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Montant en euro</Label>
                                        <Input
                                            type="select"
                                            className={this.state.isError.montant ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    montant: element.target.value
                                                }
                                            })}>
                                            {this.state.montantsDisponibles?.map((montant, index) => {
                                                return (
                                                    <option key={index} value={montant}>{parseFloat(montant).toFixed(2)}€</option>
                                                )
                                            })}
                                        </Input>
                                        <div className="invalid-feedback">
                                            Le montant doit être un entier entre 1 et 999.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-success"
                        isOpen={this.state.displayConfirmModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point de générer de nouvelles cartes prépayées.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.add()}
                            >
                                Générer
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/carteprepayees" />}
                </Container >
            </>
        );
    }

    add = async () => {
        try {
            this.setState({ displayConfirmModal: false });

            if (this.checkInputs()) {
                utils.alert.notify('success', 'Génération des QRCodes, veuillez patienter...', this.notification);
                const genId = uuid();
                const { quantite, montant } = this.state.newEntry;
                window.open(config.api.endpoint + "/cartePrepayee/generate?quantite=" + quantite + "&montant=" + montant + "&genId=" + genId);
                setTimeout(() => {
                    this.setState({ redirect: true })
                }, 5000);
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            utils.alert.notify('danger', 'Il y a eu une erreur lors de la génération.', this.notification);
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { newEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(newEntry)) {
            switch (key) {
                case 'quantite':
                    isError[key] = value == null || value === '' || value.length === 0 || value < 1 || value % 1 !== 0;
                    break;
                case 'montant':
                    isError[key] = value == null || value === '' || value.length === 0 || value % 1 !== 0 || (value < 1 || value > 999);
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
