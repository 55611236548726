
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Input,
	Container,
	Row,
	Col,
	Label,
	Button,
	Modal,
	UncontrolledTooltip,
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";
import utils from "../../../utils";

export default class AdministrateurEdit extends React.Component {
	state = {
		user: this.props.location.state.user,
		displayDeleteModal: false,
		displayUpdateModal: false,
		displayUploadDocumentModal: false,
		redirect: false,
		updatedUser: {
			firstname: this.props.location.state.user.firstname,
			lastname: this.props.location.state.user.lastname,
			phone: this.props.location.state.user.phone
		},
		isError: {
			firstname: false,
			lastname: false,
			libelle: false,
			phone: false
		}
	}

	constructor(props) {
		super(props);
		this.notification = React.createRef();
	}

	render() {
		return (
			<>
				<div className="rna-wrapper">
					<NotificationAlert ref={ref => this.notification = ref} />
				</div>
				<SimpleHeader name="Administrateurs" category="Utilisateurs" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Card className="mb-4">
						<CardHeader>
							<div className="float-left">
								<h3 className="mb-0">{this.state.user?.firstname + ' ' + this.state.user.lastname?.toUpperCase()}</h3>
							</div>
							<div className="float-right">
								<Button color="success" size="sm" type="button"
									id="updateButton"
									onClick={() => this.setState({ displayUpdateModal: true })}>
									<span className="btn-inner--icon mr-1">
										<i className="fas fa-user-edit" />
									</span>
									Mettre à jour
								</Button>
								<UncontrolledTooltip delay={0} target="updateButton">
									Enregistrer les modifications
								</UncontrolledTooltip>
								<Button color="danger" size="sm" type="button"
									id="deleteButton"
									onClick={() => this.setState({ displayDeleteModal: true })}>
									<span className="btn-inner--icon mr-1">
										<i className="fas fa-trash" />
									</span>
									Supprimer
								</Button>
								<UncontrolledTooltip delay={0} target="deleteButton">
									Supprimer l'élement
								</UncontrolledTooltip>
							</div>
						</CardHeader>
						<CardBody>
							<Row>
								<Col>
									<FormGroup>
										<Label>Prénom</Label>
										<Input
											id="firstname"
											placeholder="Prénom"
											type="text"
											className={this.state.isError.firstname ? "is-invalid" : ""}
											defaultValue={this.state.user.firstname}
											onChange={element => this.setState({
												updatedUser: {
													...this.state.updatedUser,
													firstname: element.target.value
												}
											})}
										/>
										<div className="invalid-feedback">
											Le prénom est incorrect.
										</div>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label>Nom</Label>
										<Input
											id="lastname"
											placeholder="Nom de famille"
											type="text"
											className={this.state.isError.lastname ? "is-invalid" : ""}
											defaultValue={this.state.user.lastname}
											onChange={element => this.setState({
												updatedUser: {
													...this.state.updatedUser,
													lastname: element.target.value
												}
											})}
										/>
										<div className="invalid-feedback">
											Le nom de famille est incorrect.
										</div>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md="6">
									<FormGroup>
										<Label>Adresse e-mail</Label>
										<Input
											id="email"
											placeholder="E-mail"
											type="email"
											className={this.state.isError.email ? "is-invalid" : ""}
											defaultValue={this.state.user.email}
											disabled
										/>
										<div className="invalid-feedback">
											L'adresse e-mail est incorrecte.
										</div>
									</FormGroup>
								</Col>
								<Col md="6">
									<FormGroup>
										<Label>Téléphone</Label>
										<Input
											id="phone"
											placeholder="Téléphone"
											type="tel"
											maxLength={10}
											defaultValue={this.state.user.phone}
											className={this.state.isError.phone ? "is-invalid" : ""}
											onChange={element => this.setState({
												updatedUser: {
													...this.state.updatedUser,
													phone: element.target.value
												}
											})}
										/>
										<div className="invalid-feedback">
											Le téléphone est incorrect.
										</div>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<h5 className="text-muted">Inscrit le {moment(this.state.user.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
								</Col>
							</Row>
							{this.state.user.updatedAt !== this.state.user.createdAt &&
								<Row>
									<Col>
										<h5 className="text-muted">Mis à jour le {moment(this.state.user.updatedAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
									</Col>
								</Row>}
						</CardBody>
					</Card>
					<Modal
						className="modal-dialog-centered modal-danger"
						contentClassName="bg-gradient-danger"
						isOpen={this.state.displayDeleteModal}
					>
						<div className="modal-header">
							<h6
								className="modal-title"
								id="modal-title-notification"
							>
								Confirmation
							</h6>
							<button
								aria-label="Close"
								className="close"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayDeleteModal: false })}
							>
								<span aria-hidden={true}>×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="py-3 text-center">
								<i className="ni ni-bell-55 ni-3x" />
								<h4 className="heading mt-4">
									Attention!
								</h4>
								<p>
									Vous êtes sur le point du supprimer le compte administrateur de {this.state.user?.firstname + ' ' + this.state.user?.lastname?.toUpperCase()},
									cette action est irréversible.
								</p>
							</div>
						</div>
						<div className="modal-footer">
							<Button
								className="btn-white"
								color="default"
								type="button"
								onClick={() => this.deleteUser()}
							>
								Supprimer
							</Button>
							<Button
								className="text-white ml-auto"
								color="link"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayDeleteModal: false })}
							>
								Annuler
							</Button>
						</div>
					</Modal>

					<Modal
						className="modal-dialog-centered modal-danger"
						contentClassName="bg-gradient-info"
						isOpen={this.state.displayUpdateModal}
					>
						<div className="modal-header">
							<h6
								className="modal-title"
								id="modal-title-notification"
							>
								Confirmation
							</h6>
							<button
								aria-label="Close"
								className="close"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayUpdateModal: false })}
							>
								<span aria-hidden={true}>×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="py-3 text-center">
								<i className="ni ni-bell-55 ni-3x" />
								<h4 className="heading mt-4">
									Attention!
								</h4>
								<p>
									Vous êtes sur le point de mettre à jour le compte administrateur de {this.state.user?.firstname + ' ' + this.state.user?.lastname?.toUpperCase()},
									cette action est irréversible.
								</p>
							</div>
						</div>
						<div className="modal-footer">
							<Button
								className="btn-white"
								color="default"
								type="button"
								onClick={() => this.updateUser()}
							>
								Mettre à jour
							</Button>
							<Button
								className="text-white ml-auto"
								color="link"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayUpdateModal: false })}
							>
								Annuler
							</Button>
						</div>
					</Modal>
					{this.state.redirect && <Redirect to="/admin/administrateurs" />}
				</Container>
			</>
		);
	}

	deleteUser = async () => {
		try {
			const URL = `/pro/delete/${this.state.user.id}`;
			this.setState({ displayDeleteModal: false });

			let response = await utils.apiRequest.send(URL, 'DELETE');

			if (response.result) {
				utils.alert.notify('success', 'Le compte a été supprimé.', this.notification);
				setTimeout(() => {
					this.setState({ redirect: true })
				}, 1000)
			} else {
				utils.alert.notify('danger', response.error, this.notification);
			}
		} catch (error) {
			if (error.message.endsWith('403')) {
				utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
			} else {
				utils.alert.notify('danger', 'Il y a eu une erreur lors de la suppression.', this.notification);
			}
		}
	}

	updateUser = async () => {
		try {
			const URL = `/pro/update/${this.state.user.id}`;
			this.setState({ displayUpdateModal: false });

			if (this.checkInputs()) {
				let response = await utils.apiRequest.send(URL, 'POST', this.state.updatedUser);

				if (response.result) {
					utils.alert.notify('success', 'Le compte administrateur a été mis à jour.', this.notification);
					setTimeout(() => {
						this.setState({ redirect: true })
					}, 1000)
				} else {
					throw new Error();
				}
			} else {
				utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
			}
		} catch (error) {
			if (error.message.endsWith('403')) {
				utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
			} else {
				utils.alert.notify('danger', 'Il y a eu une erreur lors de la mise à jour.', this.notification);
			}
		}
	}

	checkInputs = () => {
		let { isError } = this.state;
		let { updatedUser } = this.state;
		let areInputsCorrect = true;

		for (const [key, value] of Object.entries(updatedUser)) {
			switch (key) {
				case 'firstname':
					isError[key] = updatedUser.isAdmin && (/\d/.test(value) || value == null || value.length < 2);
					break;
				case 'lastname':
					isError[key] = updatedUser.isAdmin && (/\d/.test(value) || value == null || value.length < 2);
					break;
				case 'libelle':
					isError[key] = !updatedUser.isAdmin && (value == null || value.length < 2);
					break;
				case 'phone':
					isError[key] = value?.length > 0 ? (value.length !== 10 || isNaN(value)) : false;
					break;
				case 'city':
					isError[key] = !updatedUser.isAdmin && (value == null || value.length < 2);
					break;
				case 'zipcode':
					isError[key] = !updatedUser.isAdmin && (value == null || value.length !== 5 || isNaN(value));
					break;
				case 'address':
					isError[key] = !updatedUser.isAdmin && (value == null || value.length < 2);
					break;
				case 'isArtisan':
					isError.role = typeof value !== 'boolean' || ((updatedUser.isArtisan === false && updatedUser.isLaveur === false && updatedUser.isLivreur === false) && updatedUser.isAdmin === false)
					break;
				case 'isLaveur':
					isError.role = typeof value !== 'boolean' || ((updatedUser.isArtisan === false && updatedUser.isLaveur === false && updatedUser.isLivreur === false) && updatedUser.isAdmin === false)
					break;
				case 'isLivreur':
					isError.role = typeof value !== 'boolean' || ((updatedUser.isArtisan === false && updatedUser.isLaveur === false && updatedUser.isLivreur === false) && updatedUser.isAdmin === false)
					break;
				default:
					break;
			}
		}
		this.setState({ isError });

		// Retourne false si un des champs est incorrect
		for (const [key, value] of Object.entries(isError)) {
			if (value) areInputsCorrect = false;
			if (key) { }
		}

		return areInputsCorrect;
	}
}
