import Dashboard from "views/pages/Dashboard.js";
import Login from "views/pages/auth/Login.js";
import Users from "views/pages/Users.js";
import Cycles from "views/pages/Cycles.js";
// import Refunds from "views/pages/Refunds.js";
import Artisans from "views/pages/artisans/Artisans.js";
import ArtisanEdit from "views/pages/artisans/ArtisanEdit.js";
import ArtisanAdd from "views/pages/artisans/ArtisanAdd.js";
import Laveurs from "views/pages/laveurs/Laveurs.js";
import LaveurAdd from "views/pages/laveurs/LaveurAdd.js";
import LaveurEdit from "views/pages/laveurs/LaveurEdit.js";
import Livreurs from "views/pages/livreurs/Livreurs.js";
import LivreurEdit from "views/pages/livreurs/LivreurEdit.js";
import LivreurAdd from "views/pages/livreurs/LivreurAdd.js";
import Administrateurs from "views/pages/administrateurs/Administrateurs.js";
import AdministrateurAdd from "views/pages/administrateurs/AdministrateurAdd";
import AdministrateurEdit from "views/pages/administrateurs/AdministrateurEdit";
import ProductTypes from "views/pages/product-types/ProductTypes";
import ProductTypeAdd from "views/pages/product-types/ProductTypeAdd";
import ProductTypeEdit from "views/pages/product-types/ProductTypeEdit";
import Products from "views/pages/products/Products";
import ProductAdd from "views/pages/products/ProductAdd";
import ProductEdit from "views/pages/products/ProductEdit";
import Orders from "views/pages/orders/Orders";
import OrderAdd from "views/pages/orders/OrderAdd";
import OrderEdit from "views/pages/orders/OrderEdit";
import Caisses from "views/pages/caisses/Caisses";
import CaisseGenerate from "views/pages/caisses/CaisseGenerate";
import CartesPrepayees from "views/pages/cartes-prepayees/CartesPrepayees";
import CartesPrepayeesGenerate from "views/pages/cartes-prepayees/CartesPrepayeesGenerate";
import Consignes from "views/pages/consignes/Consignes";
import ConsigneGenerate from "views/pages/consignes/ConsigneGenerate";
import ConsigneAdd from "views/pages/consignes/ConsigneAdd";
import ConsigneEdit from "views/pages/consignes/ConsigneEdit";
import Coupons from "views/pages/coupons/Coupons";
// import JetonTypes from "views/pages/jeton-types/JetonTypes";
// import JetonTypeAdd from "views/pages/jeton-types/JetonTypeAdd";
// import JetonTypeEdit from "views/pages/jeton-types/JetonTypeEdit";
import Stock from "views/pages/statistiques/Stock";
import Flux from "views/pages/statistiques/Flux";
import Actualites from "views/pages/actualites/Actualites";
import ActualiteAdd from "views/pages/actualites/ActualiteAdd";
import ActualiteEdit from "views/pages/actualites/ActualiteEdit";
// import Settings from "views/pages/Settings";

const routes = [
	{
		path: "/dashboard",
		name: "Accueil",
		component: Dashboard,
		icon: "fas fa-home text-primary",
		layout: "/admin"
	},
	{
		collapse: true,
		name: "Produits",
		icon: "fas fa-glass-whiskey text-red",
		state: "productsCollapse",
		views: [
			{
				path: "/product-types",
				name: "Type de produits",
				miniName: "TP",
				// icon: "ni ni-box-2 text-orange",
				component: ProductTypes,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/product-type/add",
				component: ProductTypeAdd,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/product-type/edit",
				component: ProductTypeEdit,
				layout: "/admin"
			},
			{
				path: "/products",
				name: "Produits",
				miniName: "P",
				// icon: "fas fa-glass-whiskey text-red",
				component: Products,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/product/add",
				component: ProductAdd,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/product/edit",
				component: ProductEdit,
				layout: "/admin"
			},
			{
				path: "/caisses",
				name: "Caisses",
				miniName: "C",
				// icon: "fas fa-boxes text-blue",
				component: Caisses,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/caisse/generate",
				component: CaisseGenerate,
				layout: "/admin"
			},
			{
				path: "/consignes",
				name: "Consignes",
				miniName: "C",
				// icon: "fas fa-qrcode text-black",
				component: Consignes,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/consigne/add",
				component: ConsigneAdd,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/consigne/generate",
				component: ConsigneGenerate,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/consigne/edit",
				component: ConsigneEdit,
				layout: "/admin"
			},
			{
				path: "/cycles",
				name: "Cycles",
				miniName: "C",
				// icon: "fas fa-qrcode text-black",
				component: Cycles,
				layout: "/admin",
			}
		]
	},
	{
		collapse: true,
		name: "Utilisateurs",
		icon: "fas fa-users text-green",
		state: "usersCollapse",
		views: [
			{
				path: "/artisans",
				name: "Artisans",
				miniName: "A",
				// icon: "fas fa-utensils text-purple",
				component: Artisans,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/artisan/add",
				component: ArtisanAdd,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/artisan/edit",
				component: ArtisanEdit,
				layout: "/admin"
			},
			{
				path: "/livreurs",
				name: "Livreurs",
				miniName: "L",
				// icon: "fas fa-truck text-blue",
				component: Livreurs,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/livreur/add",
				component: LivreurAdd,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/livreur/edit",
				component: LivreurEdit,
				layout: "/admin"
			},
			{
				path: "/laveurs",
				name: "Laveurs",
				miniName: "L",
				// icon: "fas fa-hands-wash text-yellow",
				component: Laveurs,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/laveur/add",
				component: LaveurAdd,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/laveur/edit",
				component: LaveurEdit,
				layout: "/admin"
			},
			{
				path: "/administrateurs",
				name: "Administrateurs",
				miniName: "A",
				// icon: "fas fa-user-tie text-red",
				component: Administrateurs,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/administrateur/add",
				component: AdministrateurAdd,
				layout: "/admin"
			},
			{
				hidden: true,
				path: "/administrateur/edit",
				component: AdministrateurEdit,
				layout: "/admin"
			},
			{
				path: "/users",
				name: "Utilisateurs Bolhero",
				miniName: "U",
				// icon: "fas fa-users text-green",
				component: Users,
				layout: "/admin",
			},
		],
	},
	{
		path: "/orders",
		name: "Commandes",
		icon: "fas fa-truck-loading text-orange",
		component: Orders,
		layout: "/admin",
	},
	{
		hidden: true,
		path: "/order/add",
		component: OrderAdd,
		layout: "/admin"
	},
	{
		hidden: true,
		path: "/order/edit",
		component: OrderEdit,
		layout: "/admin"
	},
	{
		path: "/carteprepayees",
		name: "Cartes prepayées",
		icon: "fas fa-address-card text-yellow",
		component: CartesPrepayees,
		layout: "/admin",
	},
	{
		hidden: true,
		path: "/carteprepayee/generate",
		component: CartesPrepayeesGenerate,
		layout: "/admin",
	},
	{
		path: "/coupons",
		name: "Coupons de fidélité",
		icon: "fas fa-ticket-alt text-pink",
		component: Coupons,
		layout: "/admin",
	},
	// {
	//   path: "/refunds",
	//   name: "Remboursements",
	//   icon: "fas fa-file-invoice-dollar text-purple",
	//   component: Refunds,
	//   layout: "/admin",
	// },
	// {
	//   path: "/jeton-types",
	//   name: "Jetons",
	//   icon: " fas fa-coins text-yellow",
	//   component: JetonTypes,
	//   layout: "/admin",
	// },
	// {
	//   hidden: true,
	//   path: "/jeton-type/add",
	//   component: JetonTypeAdd,
	//   layout: "/admin"
	// },
	// {
	//   hidden: true,
	//   path: "/jeton-type/edit",
	//   component: JetonTypeEdit,
	//   layout: "/admin"
	// },
	{
		collapse: true,
		name: "Statistiques",
		icon: "fas fa-solid fa-chart-pie text-blue",
		state: "statistiquesCollapse",
		views: [
			{
				path: "/stock",
				name: "Stock",
				miniName: "S",
				// icon: "fas fa-solid fa-chart-pie text-blue",
				component: Stock,
				layout: "/admin",
			},
			{
				path: "/flux",
				name: "Flux",
				miniName: "SA",
				// icon: "fas fa-solid fa-chart-pie text-blue",
				component: Flux,
				layout: "/admin",
			},
		]
	},
	{
		collapse: true,
		name: "Divers",
		icon: "fas fa-hashtag text-red",
		state: "diversCollapse",
		views: [
			{
				path: "/actualites",
				name: "Actualités",
				miniName: "A",
				// icon: "fas fa-newspaper text-red",
				component: Actualites,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/actualite/add",
				component: ActualiteAdd,
				layout: "/admin",
			},
			{
				hidden: true,
				path: "/actualite/edit",
				component: ActualiteEdit,
				layout: "/admin",
			},
		]
	},
	// {
	//   path: "/settings",
	//   name: "Paramètres",
	//   icon: "ni ni-settings text-grey",
	//   component: Settings,
	//   layout: "/admin",
	// },
	{
		hidden: true,
		path: "/login",
		name: "Login",
		miniName: "L",
		component: Login,
		layout: "/auth",
	},
];

export default routes;
