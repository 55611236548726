const notify = (type, message, ref) => {
    const options = {
        place: "tc",
        message: (
            <div className="alert-text">
                <span className="alert-title" data-notify="title">
                    Notification
                </span>
                <span data-notify="message">
                    {message}
                </span>
            </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 3,
    };

    ref.notificationAlert(options);
}

const alert = {
    notify
}

export default alert;