
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Button
} from "reactstrap";
import { Link } from "react-router-dom";
import utils from "../../../utils/index";
import moment from 'moment';
import 'moment/locale/fr';
import NotificationAlert from "react-notification-alert";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { saveAs } from 'file-saver';
import bolheroApiService from "../../../services/bolheroApiService";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";

export default class Caisses extends React.Component {
    state = {
        entries: [],
        loading: true,
        filters: {
            'numero': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        }
    }

    async componentDidMount() {
        const response = await utils.apiRequest.send('caisse?order=createdAt&sort=DESC');
        if (response.result) {
            this.setState({ entries: response.result });
        }
        this.setState({ loading: false });
    }

    downloadQrCode = async (caisse) => {
        try {
            const response = await bolheroApiService.request({ url: `/caisse/qrcode?id=${caisse.id}`, responseType: 'blob' });
            if (response) {
                saveAs(response.data, `Caisse-${caisse.numero}-${caisse.numero}.pdf`);
            }
        } catch (error) {
            utils.alert.notify('danger', "Il y a eu une erreur lors de l'exportation", this.notification);
        }
    }

    render() {
        const { entries, filters, loading } = this.state;

        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Caisses" category="Produits" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Caisses</h3>
                                    </div>
                                    <div className="float-right">
                                        <Link className="table-action" to={{
                                            pathname: "/admin/caisse/generate"
                                        }} >
                                            <Button color="success" size="sm" type="button"
                                                id="generateButton">

                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-qrcode" />
                                                </span>
                                                Générer
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="generateButton" >
                                                Générer de nouvelles caisses
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </CardHeader>

                                <DataTable value={entries} filters={filters}
                                    paginator className="p-datatable-customers" stripedRows size="small" rows={20}
                                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="Aucun élément">
                                    <Column field="id" header="#" />
                                    <Column field="libelle" header="Libellé" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} />
                                    <Column field="numero" header="Numéro" filter filterPlaceholder="Saisissez..." />
                                    <Column field="createdAt" header="Créée le" dataType="date" sortable
                                        style={{ minWidth: '15rem' }}
                                        body={value => moment(value.createdAt).locale('fr').format('dddd Do MMMM [à] HH[h]mm')} />

                                    <Column header="Actions"
                                        body={value =>
                                            <div>
                                                <Button color="primary" size="sm" type="button" id={`qrcode${value.id}`}
                                                    onClick={() => this.downloadQrCode(value)}>
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-qrcode" />
                                                    </span>
                                                    Télécharger
                                                </Button>
                                                <UncontrolledTooltip delay={0} target={`qrcode${value.id}`} >
                                                    Télécharger le QRCode
                                                </UncontrolledTooltip>
                                            </div>
                                        }
                                        style={{ minWidth: '8rem' }} />
                                </DataTable>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}
