
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
    Form
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import utils from '../../../utils';
import config from "../../../config";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default class ProductAdd extends React.Component {
    state = {
        redirect: false,
        newEntry: {
            reference: null,
            referenceEtiquette: null,
            libelle: null,
            taille: null,
            noteEnvironnementale: null,
            contenance: '',
            nbParCaisse: null,
            montantConsigne: null,
            nbJetons: null,
            typeProduitId: null,
            typeJetonId: null,
            image: null,
            isDemo: false
        },
        typesProduit: [],
        typesJeton: [],
        isError: {
            reference: false,
            referenceEtiquette: false,
            libelle: false,
            taille: false,
            noteEnvironnementale: false,
            contenance: false,
            nbParCaisse: false,
            montantConsigne: false,
            nbJetons: false,
            typeProduitId: false,
            typeJetonId: false
        },
        displayConfirmModal: false
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        const typesProduit = await utils.getDataDropdown('typeProduit');
        const typesJeton = await utils.getDataDropdown('typeJeton');
        this.setState({ typesProduit: typesProduit, typesJeton: typesJeton });
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Produits" category="Produits" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Ajouter un produit</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="createButton"
                                    onClick={() => this.setState({ displayConfirmModal: true })}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-plus" />
                                    </span>
                                    Ajouter
                                </Button>
                                <UncontrolledTooltip delay={0} target="createButton">
                                    Ajouter un produit
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Référence</Label>
                                        <Input
                                            id="reference"
                                            placeholder="ex: 93983"
                                            type="text"
                                            className={this.state.isError.reference ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    reference: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La référence est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Référence étiquette</Label>
                                        <Input
                                            id="referenceEtiquette"
                                            placeholder="ex: BH22"
                                            type="text"
                                            className={this.state.isError.referenceEtiquette ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    referenceEtiquette: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La référence est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input
                                            id="libelle"
                                            placeholder="ex: Bol en verre"
                                            type="text"
                                            className={this.state.isError.libelle ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    libelle: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le libellé est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Nombre de produit par caisse</Label>
                                        <Input
                                            id="nbParCaisse"
                                            placeholder="ex: 50"
                                            type="text"
                                            className={this.state.isError.nbParCaisse ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    nbParCaisse: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le nombre par caisse est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Montant consigne (€)</Label>
                                        <Input
                                            id="montantConsigne"
                                            placeholder="ex: 1"
                                            type="number"
                                            className={this.state.isError.montantConsigne ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    montantConsigne: element.target.value
                                                }
                                            })}
                                            disabled={this.state.newEntry.isDemo}
                                        />
                                        <div className="invalid-feedback">
                                            Le montant de la consigne est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Contenance (en cc)</Label>
                                        <Input
                                            id="contenance"
                                            placeholder="ex: 210"
                                            type="text"
                                            className={this.state.isError.contenance ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    contenance: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La contenance est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Taille (en mm)</Label>
                                        <Input
                                            id="taille"
                                            placeholder="ex: Ø 70x50"
                                            type="text"
                                            className={this.state.isError.taille ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    taille: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La taille est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Note environnementale</Label>
                                        <Input
                                            id="noteEnvironnementale"
                                            placeholder="ex: A-"
                                            type="text"
                                            className={this.state.isError.noteEnvironnementale ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    noteEnvironnementale: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            La note est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Type de produit</Label>

                                        <Input type="select"
                                            className={this.state.isError.typeProduitId ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    typeProduitId: element.target.value
                                                }
                                            })}>
                                            {this.state.typesProduit.map((type, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {type}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Input>
                                        <div className="invalid-feedback">
                                            Le type de produit est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Type de jeton</Label>

                                        <Input type="select"
                                            className={this.state.isError.typeJetonId ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    typeJetonId: element.target.value
                                                }
                                            })}>
                                            {this.state.typesJeton.map((type, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {type}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Input>
                                        <div className="invalid-feedback">
                                            Le type de produit est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Nombre de jetons par produit</Label>
                                        <Input
                                            id="nbJetons"
                                            placeholder="ex: 1"
                                            type="text"
                                            className={this.state.isError.nbJetons ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    nbJetons: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le nombre de jetons est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Image</Label>
                                        <Form>
                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="customFileLang"
                                                    lang="fr"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={element => this.setState({
                                                        newEntry: {
                                                            ...this.state.newEntry,
                                                            image: element.target.files[0]
                                                        }
                                                    })}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="customFileLang"
                                                >
                                                </label>
                                            </div>
                                        </Form>
                                        <div className="invalid-feedback">
                                            L'image est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input
                                            className="custom-control-input"
                                            id="checkboxIsDemo"
                                            type="checkbox"
                                            defaultChecked={this.state.newEntry.isDemo}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    isDemo: element.target.checked
                                                }
                                            })}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="checkboxIsDemo"
                                            id="isDemo"
                                        >
                                            Produit de démo
                                        </Label>
                                        <UncontrolledTooltip delay={0} target="isDemo">
                                            Un produit de démo est un produit à 0€ utilisable même sans carte bancaire enregistrée afin de faire tester l'application.
                                        </UncontrolledTooltip>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-success"
                        isOpen={this.state.displayConfirmModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point d'ajouter une entrée.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.add()}
                            >
                                Ajouter
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/products" />}
                </Container >
            </>
        );
    }

    add = async () => {
        try {
            const URL = '/produit/add';
            this.setState({ displayConfirmModal: false });

            if (this.checkInputs()) {
                let formData = new FormData();
                for (const [key, value] of Object.entries(this.state.newEntry)) {
                    if (value !== null) {
                        formData.append(key, value);
                    }
                }

                let response = await utils.apiRequest.send(URL, 'PUT', formData);

                if (response.result) {
                    utils.alert.notify('success', 'L\'entrée a été ajoutée.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000);
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            utils.alert.notify('danger', 'Il y a eu une erreur lors de l\'ajout.', this.notification);
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { newEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(newEntry)) {
            switch (key) {
                case 'libelle':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'reference':
                    isError[key] = value == null || value.length < 2;
                    break;
                case 'referenceEtiquette':
                    isError[key] = value == null || value.length < 2 || value.length > 6;
                    break;
                case 'contenance':
                    isError[key] = /[a-zA-Z]/g.test(value);
                    break;
                case 'nbParCaisse':
                    isError[key] = parseInt(value) === 0 || isNaN(parseInt(value)) || value == null
                    break;
                case 'nbJetons':
                    isError[key] = parseInt(value) === 0 || isNaN(parseInt(value)) || value == null
                    break;
                case 'montantConsigne':
                    isError[key] = !newEntry.isDemo && (parseInt(value) < 0 || isNaN(parseInt(value)) || value == null)
                    break;
                case 'taille':
                    isError[key] = value?.includes('m');
                    break;
                case 'typeProduitId':
                    isError[key] = value == null || isNaN(value) || value.length === 0;
                    break;
                case 'typeJetonId':
                    isError[key] = value == null || isNaN(value) || value.length === 0;
                    break;
                case 'isDemo':
                    isError[key] = typeof value !== 'boolean';
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
