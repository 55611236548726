
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Button
} from "reactstrap";
import { Link } from "react-router-dom";
import utils from "../../../utils/index";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import config from "../../../config";

export default class Administrateurs extends React.Component {
    state = {
        users: [],
        apiError: false,
        loading: true,
        filters: {
            'firstname': { constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'lastname': { constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'email': { constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
        }
    }

    async componentDidMount() {
        const response = await utils.apiRequest.send('/pro?order=createdAt&sort=DESC&role=isAdmin');
        if (response.result) {
            this.setState({ users: response.result });
        }
        this.setState({ loading: false });
    }

    render() {
        const { users, filters, loading } = this.state;

        return (
            <>
                <SimpleHeader name="Administrateurs" category="Utilisateurs" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Administrateurs</h3>
                                        <p className="text-muted">
                                            Liste des personnes ayant accès au backoffice de Bolhero.
                                        </p>
                                    </div>
                                    <div className="float-right">
                                        <Link className="table-action" to={{
                                            pathname: "/admin/administrateur/add"
                                        }} >
                                            <Button color="success" size="sm" type="button"
                                                id="createButton">

                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-plus" />
                                                </span>
                                                Ajouter
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="createButton" >
                                                Créer un nouveau compte administrateur
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </CardHeader>

                                <DataTable value={users} filters={filters}
                                    paginator className="p-datatable-customers" stripedRows size="small" rows={20}
                                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                                    globalFilterFields={['firstname', 'lastname', 'email', 'createdAt']} emptyMessage="Aucun élément">
                                    <Column field="firstname" header="Prénom" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} sortable />
                                    <Column field="lastname" header="Nom" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} sortable />
                                    <Column field="email" header="Email" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} />
                                    <Column field="phone" header="Téléphone" style={{ minWidth: '12rem' }}
                                        body={value => typeof value.phone === 'string' ? value.phone.replace(/(.{2})/g, "$1 ") : 'Aucun'} />
                                    <Column field="createdAt" header="Date d'ajout" dataType="date" sortable
                                        style={{ minWidth: '10rem' }}
                                        body={value => moment(value.createdAt).format('DD/MM/YYYY')} />
                                    <Column header="Actions"
                                        body={user =>
                                            <div>
                                                <Link className="table-action" to={{
                                                    pathname: "/admin/administrateur/edit",
                                                    state: { user: user }
                                                }} >
                                                    <Button color="primary" size="sm" type="button">
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-edit" />
                                                        </span>
                                                        Éditer
                                                    </Button>
                                                </Link>
                                            </div>
                                        }
                                        style={{ minWidth: '8rem' }} />
                                </DataTable>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}
