import axios from 'axios';
import config from '../config';

const apiService = axios.create({
  withCredentials: true,
  baseURL: config.api.endpoint,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default apiService;
