
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import utils from '../../../utils';
import config from "../../../config";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default class ProductTypeAdd extends React.Component {
    state = {
        redirect: false,
        newEntry: {
            libelle: null,
        },
        isError: {
            libelle: false
        },
        displayConfirmModal: false
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Types de produit" category="Produits" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Ajouter un type de produit</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="createButton"
                                    onClick={() => this.setState({ displayConfirmModal: true })}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-plus" />
                                    </span>
                                    Ajouter
                                </Button>
                                <UncontrolledTooltip delay={0} target="createButton">
                                    Ajouter un type de produit
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Libellé</Label>
                                        <Input
                                            id="libelle"
                                            placeholder="Libellé"
                                            type="text"
                                            className={this.state.isError.libelle ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    libelle: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le libellé est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-success"
                        isOpen={this.state.displayConfirmModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point d'ajouter un type de produit.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.add()}
                            >
                                Ajouter
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/product-types" />}
                </Container >
            </>
        );
    }

    add = async () => {
        try {
            const URL = '/typeProduit/add';
            this.setState({ displayConfirmModal: false });

            if (this.checkInputs()) {
                let response = await utils.apiRequest.send(URL, 'PUT', this.state.newEntry);

                if (response.result) {
                    utils.alert.notify('success', 'Le type de produit a été ajouté.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000);
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            utils.alert.notify('danger', 'Il y a eu une erreur lors de l\'ajout.', this.notification);
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { newEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(newEntry)) {
            switch (key) {
                case 'libelle':
                    isError[key] = value == null || value.length < 2;
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
