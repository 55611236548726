
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
// prime react
import { locale, addLocale } from 'primereact/api';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';


import AdminLayout from "layouts/Admin.js";
// import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
//import IndexView from "views/Index.js";
import ProtectedRoute from 'components/Routes/ProtectedRoute';

addLocale('fr', {
  firstDayOfWeek: 0,
  dayNames: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
  dayNamesShort: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'],
  dayNamesMin: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
  monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'decembre'],
  monthNamesShort: ['janv', 'fév', 'mar', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'dec'],
  today: 'Aujourd\'hui',
  clear: 'Vider',
  filter: 'Filtres',
  firstPageLabel: 'Première page',
  previousPageLabel: 'Page précédente',
  nextPageLabel: 'Page suivante',
  lastPageLabel: 'Dernière page',
  choose: 'Sélectionner',
  matchAll: 'Correspond à toutes les règles',
  matchAny: 'Correspond à au moins une règle',
  startsWith: 'Commence par',
  contains: 'Contient',
  notContains: 'Ne contient pas',
  endsWith: 'Fini par',
  equals: 'Égal',
  notEquals: 'N\'est pas égal',
  noFilter: 'Pas de filtres',
  addRule: 'Ajouter une règle',
  removeRule: 'Supprimer la règle',
  apply: 'Valider',
  dateIs: 'La date est',
  dateIsNot: 'La date n\'est pas',
  dateBefore: 'La date est avant',
  dateAfter: 'La date est après',
  emptyFilterMessage: 'Aucun résultat'
});

locale('fr');

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <ProtectedRoute path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/" render={(props) => <AdminLayout {...props} />} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
