
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
    Form
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";
import utils from "../../../utils/index";

export default class ConsigneEdit extends React.Component {
    state = {
        entry: this.props.location.state.entry,
        displayDeleteModal: false,
        displayHorsServiceModal: false,
        redirect: false,
        updatedEntry: {
            numero: this.props.location.state.entry.numero,
            dateMiseHorsService: this.props.location.state.entry.dateMiseHorsService,
            raisonHorsService: this.props.location.state.entry.raisonHorsService,
            produitId: this.props.location.state.entry.produit?.id
        },
        produits: [],
        isError: {
            numero: false,
            dateMiseHorsService: false,
            raisonHorsService: false,
            produitId: false
        }
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        const produits = await utils.getDataDropdown('produit');
        this.setState({ produits: produits });
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Consignes" category="Produits" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Consigne #{this.state.entry.numero} ({this.state.entry.produit.libelle})</h3>
                            </div>
                            <div className="float-right">
                                <Button color="warning" size="sm" type="button"
                                    id="updateButton"
                                    onClick={() => this.setState({ displayHorsServiceModal: true, updatedEntry: { ...this.state.updatedEntry, dateMiseHorsService: moment().format('YYYY-MM-DD[T]hh:mm') } })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-ban" />
                                    </span>
                                    Hors service
                                </Button>
                                <UncontrolledTooltip delay={0} target="updateButton">
                                    Passer la consigne en statut "hors service"
                                </UncontrolledTooltip>
                                <Button color="danger" size="sm" type="button"
                                    id="deleteButton"
                                    onClick={() => this.setState({ displayDeleteModal: true })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-trash" />
                                    </span>
                                    Supprimer
                                </Button>
                                <UncontrolledTooltip delay={0} target="deleteButton">
                                    Supprimer l'élement
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Numéro</Label>
                                        <Input
                                            placeholder="ex: 19832"
                                            type="text"
                                            defaultValue={this.state.entry.numero}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Date de mise en service</Label>
                                        <Input
                                            type="datetime-local"
                                            defaultValue={moment(this.state.entry.dateMiseService).format('YYYY-MM-DD[T]hh:mm')}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Produit</Label>
                                        <Input type="select"
                                            disabled
                                            className={this.state.isError.produitId ? "is-invalid" : ""}
                                            value={this.state.updatedEntry.produitId}>
                                            {this.state.produits.map((type, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {type}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className="text-muted">Ajouté le {moment(this.state.entry.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                </Col>
                            </Row>
                            {this.state.entry.updatedAt !== this.state.entry.createdAt &&
                                <Row>
                                    <Col>
                                        <h5 className="text-muted">Mis à jour le {moment(this.state.entry.updatedAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                    </Col>
                                </Row>}
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-danger"
                        isOpen={this.state.displayDeleteModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point du supprimer cette entrée,
                                    cette action est irréversible.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.delete()}
                            >
                                Supprimer
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        className="modal-dialog-centered"
                        size="sm"
                        isOpen={this.state.displayHorsServiceModal}
                    >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <h3>Passer la consigne hors service</h3>
                                    </div>
                                    <Form role="form">
                                        <FormGroup>
                                            <Label>Date de mise hors service</Label>
                                            <Input
                                                type="datetime-local"
                                                className={this.state.isError.dateMiseHorsService ? "is-invalid" : ""}
                                                value={moment(this.state.entry.dateMiseHorsService || this.state.updatedEntry.dateMiseHorsService).format('YYYY-MM-DD[T]hh:mm')}
                                                onChange={element => this.setState({
                                                    updatedEntry: {
                                                        ...this.state.updatedEntry,
                                                        dateMiseHorsService: element.target.value
                                                    }
                                                })}
                                                disabled={this.state.entry.dateMiseHorsService !== null}
                                            />
                                            <div className="invalid-feedback">
                                                La date est incorrecte.
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Raison hors service</Label>
                                            <Input
                                                id="raisonHs"
                                                placeholder="Consigne cassée"
                                                type="text"
                                                className={this.state.isError.raisonHorsService ? "is-invalid" : ""}
                                                defaultValue={this.state.entry.raisonHorsService}
                                                onChange={element => this.setState({
                                                    updatedEntry: {
                                                        ...this.state.updatedEntry,
                                                        raisonHorsService: element.target.value
                                                    }
                                                })}
                                            />
                                            <div className="invalid-feedback">
                                                La raison est incorrecte.
                                            </div>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button
                                                className="my-4"
                                                color="success"
                                                type="button"
                                                onClick={() => this.update()}
                                            >
                                                Valider
                                            </Button>
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => this.setState({ displayHorsServiceModal: false })}
                                            >
                                                Fermer
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/consignes" />}
                </Container>
            </>
        );
    }

    delete = async () => {
        try {
            const URL = `/consigne/delete/${this.state.entry.id}`;
            this.setState({ displayDeleteModal: false });

            let response = await utils.apiRequest.send(URL, 'DELETE');

            if (response.result) {
                utils.alert.notify('success', 'L\'entrée a été supprimée.', this.notification);
                setTimeout(() => {
                    this.setState({ redirect: true })
                }, 1000)
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la suppression.', this.notification);
            }
        }
    }

    update = async () => {
        try {
            const URL = `/consigne/update/${this.state.entry.id}`;

            if (this.checkInputs()) {
                this.setState({ displayHorsServiceModal: false });

                let response = await utils.apiRequest.send(URL, 'POST', this.state.updatedEntry);

                if (response.result) {
                    utils.alert.notify('success', 'L\'entrée a été mise à jour.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000)
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte.', this.notification);
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
            } else {
                utils.alert.notify('danger', 'Il y a eu une erreur lors de la mise à jour.', this.notification);
            }
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { updatedEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(updatedEntry)) {
            switch (key) {
                case 'raisonHorsService':
                    isError[key] = this.state.updatedEntry.dateMiseHorsService && (value == null || value.length < 2);
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}
