
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
    Form
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import utils from "../../../utils";
import config from "../../../config";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default class ActualiteAdd extends React.Component {
    state = {
        redirect: false,
        newEntry: {
            titre: null,
            texte: null,
            image: null,
            url: null,
        },
        isError: {
            titre: false,
            texte: false,
            image: false,
            url: false
        },
        displayConfirmModal: false
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Actualités" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Actualités</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="createButton"
                                    onClick={() => this.setState({ displayConfirmModal: true })}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-plus" />
                                    </span>
                                    Publier
                                </Button>
                                <UncontrolledTooltip delay={0} target="createButton">
                                    La publication sera disponible sur l'application Bolhero
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="7">
                                    <FormGroup>
                                        <Label>Titre</Label>
                                        <Input
                                            id="libelle"
                                            placeholder="Libellé"
                                            type="text"
                                            className={this.state.isError.titre ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    titre: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le titre est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="5">
                                    <FormGroup>
                                        <Label>Image</Label>
                                        <Form>
                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="customFileLang"
                                                    lang="fr"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={element => this.setState({
                                                        newEntry: {
                                                            ...this.state.newEntry,
                                                            image: element.target.files[0]
                                                        }
                                                    })}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="customFileLang"
                                                >
                                                </label>
                                            </div>
                                        </Form>
                                        <div className="invalid-feedback">
                                            L'image est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Texte</Label>
                                        <Input
                                            rows="10"
                                            type="textarea"
                                            className={this.state.isError.texte ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    texte: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le texte est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Lien</Label>
                                        <Input
                                            id="url"
                                            placeholder="https://www.google.com/"
                                            type="text"
                                            className={this.state.isError.url ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newEntry: {
                                                    ...this.state.newEntry,
                                                    url: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'url est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-success"
                        isOpen={this.state.displayConfirmModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Confirmation
                                </h4>
                                <p>
                                    Vous êtes sur le point d'ajouter une publication.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.add()}
                            >
                                Ajouter
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/actualites" />}
                </Container >
            </>
        );
    }

    add = async () => {
        try {
            this.setState({ displayConfirmModal: false });

            if (this.checkInputs()) {
                const { titre, texte, image, url } = this.state.newEntry;

                let formData = new FormData();
                if (titre) formData.append('titre', titre);
                if (texte) formData.append('texte', texte);
                if (image) formData.append('image', image);
                if (url) formData.append('url', url);

                let response = await utils.apiRequest.send('/actualite/add', 'PUT', formData);

                if (response.result) {
                    utils.alert.notify('succes', 'L\'actualité a été publiée.', this.notification);
                    setTimeout(() => {
                        this.setState({ redirect: true })
                    }, 1000);
                } else {
                    throw new Error();
                }
            } else {
                utils.alert.notify('warning', 'Votre saisie est incorrecte, veuillez ajouter une image.', this.notification);
            }
        } catch (error) {
            utils.alert.notify('danger', 'Il y a eu une erreur lors de l\'ajout.', this.notification);
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { newEntry } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(newEntry)) {
            switch (key) {
                case 'image':
                    isError[key] = value < 1;
                    break;
                case 'url':
                    if (value !== null && value?.length !== 0) {
                        // eslint-disable-next-line 
                        let regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
                        isError[key] = !regex.test(value);
                    } else {
                        isError[key] = false;
                    }
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }
}