
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	UncontrolledTooltip,
	Container,
	Row,
	Button,
	Modal,
	Label
} from "reactstrap";
import { Link } from "react-router-dom";
import utils from "../../../utils/index";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';
import NotificationAlert from "react-notification-alert";
import config from "../../../config";

export default class Artisans extends React.Component {
	constructor(props) {
		super(props);
		this.notification = React.createRef();
	}

	state = {
		users: [],
		apiError: false,
		loading: true,
		filters: {
			'libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'email': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'active': { value: null, matchMode: FilterMatchMode.EQUALS },
		},
		displayDeleteModal: false,
		userToDelete: null
	}

	async componentDidMount() {
		await this.getData();
	}

	getData = async () => {
		this.setState({ loading: true });
		const response = await utils.apiRequest.send('/pro?order=createdAt&sort=DESC&role=isArtisan');
		if (response.result) {
			this.setState({ users: response.result });
		}
		this.setState({ loading: false });
	}

	deleteUser = async () => {
		try {
			const URL = `/pro/delete/${this.state.userToDelete.id}`;
			this.setState({ displayDeleteModal: false });

			let response = await utils.apiRequest.send(URL, 'DELETE');

			if (response) {
				if (response.result) {
					utils.alert.notify('success', 'Le compte a été supprimé.', this.notification);
					await this.getData();
				} else {
					utils.alert.notify('danger', response.error, this.notification);
				}
			} else {
				throw new Error();
			}
		} catch (error) {
			if (error.message.endsWith('403')) {
				utils.alert.notify('warning', 'Permissions insuffisantes', this.notification);
			} else {
				utils.alert.notify('danger', 'Il y a eu une erreur lors de la suppression.', this.notification);
			}
		}
	}

	render() {
		const { users, filters, loading } = this.state;

		return (
			<>
				<div className="rna-wrapper">
					<NotificationAlert ref={ref => this.notification = ref} />
				</div>
				<SimpleHeader name="Artisans" category="Utilisateurs" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Artisans</h3>
									</div>
									<div className="float-right">
										<Link className="table-action" to={{
											pathname: "/admin/artisan/add"
										}} >
											<Button color="success" size="sm" type="button"
												id="createButton">

												<span className="btn-inner--icon mr-1">
													<i className="fas fa-plus" />
												</span>
												Ajouter
											</Button>
											<UncontrolledTooltip delay={0} target="createButton" >
												Créer un nouveau compte artisan
											</UncontrolledTooltip>
										</Link>
									</div>
								</CardHeader>

								<DataTable value={users} filters={filters}
									paginator className="p-datatable-customers" size="small" stripedRows rows={20}
									dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
									globalFilterFields={['libelle', 'email', 'createdAt']} emptyMessage="Aucun élément">
									<Column field="libelle" header="Identité" filter filterPlaceholder="Saisissez..." sortable />
									<Column field="email" header="Email" filter filterPlaceholder="Saisissez..." sortable />
									<Column field="phone" header="Téléphone"
										body={value => typeof value.phone === 'string' ? value.phone.replace(/(.{2})/g, "$1 ") : 'Aucun'} />
									<Column field="city" header="Ville" sortable
										body={value => value?.city ? (value.city.charAt(0).toUpperCase() + value.city.slice(1)) : 'Inconnue'} />
									<Column field="programmeFidelite" header="Fidélité" sortable
										body={value => value.programmeFidelite ? 'Activée' : 'Non'} />
									<Column field="active" header="Actif" dataType="boolean" filter sortable
										body={rowData => <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.active, 'false-icon pi-times-circle': !rowData.active })}></i>}
										filterElement={options => {
											return (
												<Row style={{ marginLeft: 'auto' }}>
													<TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
													<Label style={{ marginLeft: 10 }}>{options.value === null ? "Tous" : options.value ? "Oui" : "Non"}</Label>
												</Row>
											)
										}
										} />
									<Column field="createdAt" header="Date d'ajout" dataType="date" sortable
										body={value => moment(value.createdAt).format('DD/MM/YYYY')} />
									<Column header="Actions"
										body={user =>
											<div>
												<Link className="table-action" to={{
													pathname: "/admin/artisan/edit",
													state: { user: user }
												}} >
													<Button color="primary" size="sm" type="button">
														<span className="btn-inner--icon mr-1">
															<i className="fas fa-edit" />
														</span>
														Éditer
													</Button>
												</Link>

												<Button color="danger" size="sm" type="button" onClick={() => this.setState({ displayDeleteModal: true, userToDelete: user })}>
													<span className="btn-inner--icon mr-1">
														<i className="fas fa-trash" />
													</span>
													Supprimer
												</Button>
											</div>
										}
										style={{ minWidth: '8rem' }} />
								</DataTable>
							</Card>
						</div>
					</Row>

					<Modal
						className="modal-dialog-centered modal-danger"
						contentClassName="bg-gradient-danger"
						isOpen={this.state.displayDeleteModal}
					>
						<div className="modal-header">
							<h6
								className="modal-title"
								id="modal-title-notification"
							>
								Confirmation
							</h6>
							<button
								aria-label="Close"
								className="close"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayDeleteModal: false })}
							>
								<span aria-hidden={true}>×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="py-3 text-center">
								<i className="ni ni-bell-55 ni-3x" />
								<h4 className="heading mt-4">
									Attention!
								</h4>
								<p>
									Vous êtes sur le point du supprimer le compte professionnel de {this.state.userToDelete?.libelle},
									cette action est irréversible.
								</p>
							</div>
						</div>
						<div className="modal-footer">
							<Button
								className="btn-white"
								color="default"
								type="button"
								onClick={() => this.deleteUser()}
							>
								Supprimer
							</Button>
							<Button
								className="text-white ml-auto"
								color="link"
								data-dismiss="modal"
								type="button"
								onClick={() => this.setState({ displayDeleteModal: false })}
							>
								Annuler
							</Button>
						</div>
					</Modal>
				</Container>
			</>
		);
	}
}
