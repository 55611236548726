
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	UncontrolledTooltip,
	Container,
	Row,
	Button,
	Label
} from "reactstrap";
import { Link } from "react-router-dom";
import utils from "../../../utils/index";
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import config from "../../../config";

export default class Laveurs extends React.Component {
	state = {
		users: [],
		apiError: false,
		loading: true,
		filters: {
			'libelle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'email': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
			'active': { value: null, matchMode: FilterMatchMode.EQUALS }
		}
	}

	async componentDidMount() {
		const response = await utils.apiRequest.send('/pro?order=createdAt&sort=DESC&role=isLaveur');
		if (response.result) {
			this.setState({ users: response.result });
		}
		this.setState({ loading: false });
	}

	render() {
		const { users, filters, loading } = this.state;

		return (
			<>
				<SimpleHeader name="Laveurs" category="Utilisateurs" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Laveurs</h3>
									</div>
									<div className="float-right">
										<Link className="table-action" to={{
											pathname: "/admin/laveur/add"
										}} >
											<Button color="success" size="sm" type="button"
												id="createButton">

												<span className="btn-inner--icon mr-1">
													<i className="fas fa-plus" />
												</span>
												Ajouter
											</Button>
											<UncontrolledTooltip delay={0} target="createButton" >
												Créer un nouveau compte laveur
											</UncontrolledTooltip>
										</Link>
									</div>
								</CardHeader>

								<DataTable value={users} filters={filters}
									paginator className="p-datatable-customers" stripedRows size="small" rows={20}
									dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
									globalFilterFields={['libelle', 'email', 'createdAt']} emptyMessage="Aucun élément">
									<Column field="libelle" header="Identité" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} sortable />
									<Column field="email" header="Email" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} sortable />
									<Column field="phone" header="Téléphone" style={{ minWidth: '12rem' }}
										body={value => typeof value.phone === 'string' ? value.phone.replace(/(.{2})/g, "$1 ") : 'Aucun'} />
									<Column field="city" header="Ville" sortable
										body={value => value?.city ? (value.city.charAt(0).toUpperCase() + value.city.slice(1)) : 'Inconnue'}
										style={{ minWidth: '12rem' }} />
									<Column field="active" header="Actif" dataType="boolean" filter sortable
										body={rowData => <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.active, 'false-icon pi-times-circle': !rowData.active })}></i>}
										filterElement={options => {
											return (
												<Row style={{ marginLeft: 'auto' }}>
													<TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
													<Label style={{ marginLeft: 10 }}>{options.value === null ? "Tous" : options.value ? "Oui" : "Non"}</Label>
												</Row>
											)
										}
										} />
									<Column field="createdAt" header="Date d'ajout" dataType="date" sortable
										style={{ minWidth: '10rem' }}
										body={value => moment(value.createdAt).format('DD/MM/YYYY')} />
									<Column header="Actions"
										body={user =>
											<div>
												<Link className="table-action" to={{
													pathname: "/admin/laveur/edit",
													state: { user: user }
												}} >
													<Button color="primary" size="sm" type="button">
														<span className="btn-inner--icon mr-1">
															<i className="fas fa-edit" />
														</span>
														Éditer
													</Button>
												</Link>
											</div>
										}
										style={{ minWidth: '8rem' }} />
								</DataTable>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}
