
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Button
} from "reactstrap";
import { Link } from "react-router-dom";
import utils from "../../../utils/index";
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../../config";

export default class ProductTypes extends React.Component {
    state = {
        entries: [],
        loading: true
    }

    async componentDidMount() {
        const response = await utils.apiRequest.send('typeProduit?order=createdAt&sort=DESC');
        if (response.result) {
            this.setState({ entries: response.result });
        }
        this.setState({ loading: false });
    }

    render() {
        const { entries, loading } = this.state;

        return (
            <>
                <SimpleHeader name="Types de produit" category="Produits" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Types de produit</h3>
                                    </div>
                                    <div className="float-right">
                                        <Link className="table-action" to={{
                                            pathname: "/admin/product-type/add"
                                        }} >
                                            <Button color="success" size="sm" type="button"
                                                id="createButton">

                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-plus" />
                                                </span>
                                                Ajouter
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="createButton" >
                                                Créer un nouveau type de produit
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </CardHeader>

                                <DataTable value={entries}
                                    paginator className="p-datatable-customers" stripedRows rows={20} size="small"
                                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="Aucun élément">
                                    <Column field="libelle" header="Libellé" sortable body={value => value.libelle.charAt(0).toUpperCase() + value.libelle.slice(1)} />
                                    <Column field="createdAt" header="Ajouté le" dataType="date" sortable
                                        body={value => moment(value.createdAt).locale('fr').format('DD/MM/YYYY')} />
                                    <Column header="Actions"
                                        body={entry =>
                                            <div>
                                                <Link className="table-action" to={{
                                                    pathname: "/admin/product-type/edit",
                                                    state: { entry: entry }
                                                }} >
                                                    <Button color="primary" size="sm" type="button">
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-edit" />
                                                        </span>
                                                        Éditer
                                                    </Button>
                                                </Link>
                                            </div>
                                        }
                                        style={{ minWidth: '8rem' }} />
                                </DataTable>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}
