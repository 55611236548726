
import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Container,
	Row,
	Label,
	Col
} from "reactstrap";
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import utils from "../../../utils";
import config from "../../../config";

export default class Stock extends React.Component {
	state = {
		loading: true,
		stock: [],
		produits: [],
		artisans: [],
		laveurs: [],
		livreurs: [],
		selectedProduit: null,
		selectedArtisan: null,
		selectedLaveur: null,
		selectedLivreur: null,
		customDateInterval: false,
		intervalDateStart: null,
		intervalDateEnd: null,
	}


	async componentDidMount() {
		const produits = await utils.apiRequest.send(`produit?order=libelle&sort=ASC&isDemo=false`);
		const artisans = await utils.apiRequest.send(`pro?order=libelle&sort=ASC&role=isArtisan`);
		const laveurs = await utils.apiRequest.send(`pro?order=libelle&sort=ASC&role=isLaveur`);
		const livreurs = await utils.apiRequest.send(`pro?order=libelle&sort=ASC&role=isLivreur`);
		produits.result.splice(0, 0, { id: null, libelle: "Tous" });
		artisans.result.splice(0, 0, { id: null, libelle: "Tous" });
		laveurs.result.splice(0, 0, { id: null, libelle: "Tous" });
		livreurs.result.splice(0, 0, { id: null, libelle: "Tous" });
		this.setState({
			produits: produits.result,
			artisans: artisans.result, laveurs: laveurs.result, livreurs: livreurs.result,
			selectedProduit: produits.result[0], selectedArtisan: artisans.result[0], selectedLaveur: laveurs.result[0], selectedLivreur: livreurs.result[0]
		});

		await this.getData();
	}

	getData = async () => {
		try {
			const { selectedProduit, selectedArtisan, selectedLaveur, selectedLivreur } = this.state;
			const data = { selectedProduit: selectedProduit?.id, selectedArtisanId: selectedArtisan?.id, selectedLaveurId: selectedLaveur?.id, selectedLivreurId: selectedLivreur?.id };

			this.setState({ loading: true });
			const response = await utils.apiRequest.send('/statistiques/stock/', 'POST', data);
			this.setState({ stock: response.result });
		} catch (error) { }

		this.setState({ loading: false });
	}

	getValeurStock = (obj) => {
		const { stock, selectedProduit } = this.state;

		return selectedProduit?.id === null
			? stock.reduce((acc, produit) => { return acc + produit[obj] }, 0)
			: stock.find(produit => produit.id === selectedProduit.id)?.[obj];
	}

	handleDropdownChange = (newValue, valueToChange) => {
		this.setState({ [valueToChange]: newValue }, () => this.getData());
	}

	render() {
		const { produits, artisans, laveurs, livreurs, selectedProduit, selectedArtisan, selectedLaveur, selectedLivreur, loading } = this.state;

		return (
			<>
				<SimpleHeader name="Stock" category="Statistiques" parentName={config.app.name} />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Card>
								<CardHeader>
									<div className="float-left">
										<h3 className="mb-0">Stock</h3>
									</div>
								</CardHeader>

								<CardBody>
									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Sélection</h3>
											</div>
										</CardHeader>
										<CardBody>
											<Row className="justify-content-center">
												<Col xs="auto">
													<Label>Produit</Label>
													<Row>
														<Dropdown
															optionLabel="libelle" placeholder="Sélectionnez..."
															value={selectedProduit} options={produits}
															onChange={e => this.handleDropdownChange(e.value, 'selectedProduit')}
															itemTemplate={o => { return <div>{o.libelle}</div> }}
															filter
															valueTemplate={(o, p) => { return o ? <div>{o.libelle}</div> : <span>{p.placeholder}</span> }}
															style={{ width: '13rem' }}
															className="ml-2 mr-5 w-full md:w-14rem" />
													</Row>
												</Col>

												<Col xs="auto">
													<Label>Artisan</Label>
													<Row>
														<Dropdown
															optionLabel="libelle" placeholder="Sélectionnez..."
															value={selectedArtisan} options={artisans}
															onChange={e => this.handleDropdownChange(e.value, 'selectedArtisan')}
															itemTemplate={o => { return <div>{o.libelle}</div> }}
															filter
															valueTemplate={(o, p) => { return o ? <div>{o.libelle}</div> : <span>{p.placeholder}</span> }}
															style={{ width: '13rem' }}
															className="ml-2 mr-5 w-full md:w-14rem" />
													</Row>
												</Col>

												<Col xs="auto">
													<Label>Laveur</Label>
													<Row>
														<Dropdown
															optionLabel="libelle" placeholder="Sélectionnez..."
															value={selectedLaveur} options={laveurs}
															onChange={e => this.handleDropdownChange(e.value, 'selectedLaveur')}
															itemTemplate={o => { return <div>{o.libelle}</div> }}
															filter
															valueTemplate={(o, p) => { return o ? <div>{o.libelle}</div> : <span>{p.placeholder}</span> }}
															style={{ width: '13rem' }}
															className="ml-2 mr-5 w-full md:w-14rem" />
													</Row>
												</Col>

												<Col xs="auto">
													<Label>Livreur</Label>
													<Row>
														<Dropdown
															optionLabel="libelle" placeholder="Sélectionnez..."
															value={selectedLivreur} options={livreurs}
															onChange={e => this.handleDropdownChange(e.value, 'selectedLivreur')}
															itemTemplate={o => { return <div>{o.libelle}</div> }}
															filter
															valueTemplate={(o, p) => { return o ? <div>{o.libelle}</div> : <span>{p.placeholder}</span> }}
															style={{ width: '13rem' }}
															className="ml-2 mr-5 w-full md:w-14rem" />
													</Row>
												</Col>
											</Row>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<div className="float-left">
												<h3 className="mb-0">Étapes</h3>
											</div>
										</CardHeader>
										<CardBody>
											{loading
												? <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" className="center" />
												: <React.Fragment>
													<Row>
														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Bolhero</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsChezBolhero'))}
																</CardBody>
															</Card>
														</Col>
														<i className="pi pi-angle-right mb-3 d-flex align-items-center" style={{ fontSize: '2.5rem' }} />

														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Livreur en livraison</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsEnLivraison'))}
																</CardBody>
															</Card>
														</Col>
														<i className="pi pi-angle-right mb-3 d-flex align-items-center" style={{ fontSize: '2.5rem' }} />

														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Artisan propre</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsPropresArtisan'))}
																</CardBody>
															</Card>
														</Col>
														<i className="pi pi-angle-right mb-3 d-flex align-items-center" style={{ fontSize: '2.5rem' }} />

														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Artisan interne</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsCycleInterneArtisan'))}
																</CardBody>
															</Card>
														</Col>
														<i className="pi pi-angle-right mb-3 d-flex align-items-center" style={{ fontSize: '2.5rem' }} />
													</Row>

													<Row>
														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Artisan sale</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsSalesChezArtisan'))}
																</CardBody>
															</Card>
														</Col>
														<i className="pi pi-angle-right mb-3 d-flex align-items-center" style={{ fontSize: '2.5rem' }} />

														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Livreur sale</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsSalesChezLivreurs'))}
																</CardBody>
															</Card>
														</Col>
														<i className="pi pi-angle-right mb-3 d-flex align-items-center" style={{ fontSize: '2.5rem' }} />

														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Laveur sale</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsSalesChezLaveur'))}
																</CardBody>
															</Card>
														</Col>
														<i className="pi pi-angle-right mb-3 d-flex align-items-center" style={{ fontSize: '2.5rem' }} />

														<Col>
															<Card>
																<CardHeader>
																	<div className="float-left">
																		<h3 className="mb-0">Laveur propre</h3>
																	</div>
																</CardHeader>
																<CardBody>
																	{utils.pluriel('contenant', this.getValeurStock('nbrContenantsPropresChezLaveur'))}
																</CardBody>
															</Card>
														</Col>
													</Row>
												</React.Fragment>}
										</CardBody>
									</Card>
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}
