
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    Container,
    Row,
} from "reactstrap";
import utils from "../../utils/index";
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "../../config";

export default class Users extends React.Component {
    state = {
        entries: [],
        loading: true,
        filters: {
            'firstname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'lastname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'email': { constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
        }
    }

    async componentDidMount() {
        const response = await utils.apiRequest.send('/utilisateur?order=createdAt&sort=DESC');
        if (response.result) {
            this.setState({ entries: response.result });
        }
        this.setState({ loading: false });
    }

    render() {
        const { entries, filters, loading } = this.state;

        return (
            <>
                <SimpleHeader name="Utilisateurs" parentName={config.app.name} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Utilisateurs</h3>
                                        <p className="text-muted">
                                            Liste des utilisateurs inscrits sur l'application Bolhero.
                                        </p>
                                    </div>
                                </CardHeader>

                                <DataTable value={entries} filters={filters}
                                    paginator className="p-datatable-customers" stripedRows rows={20} size="small"
                                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="Aucun élément">
                                    <Column field="lastname" header="Nom" sortable filter filterPlaceholder="Saisissez..." body={value => value?.lastname?.toUpperCase() ?? "-"} />
                                    <Column field="firstname" header="Prénom" filter filterPlaceholder="Saisissez..." sortable />
                                    <Column field="email" header="Email" filter filterPlaceholder="Saisissez..." style={{ minWidth: '12rem' }} />
                                    <Column field="dateValiditeCarte" header="CB active" sortable style={{ minWidth: '12rem' }}
                                        body={value => moment(value.dateValiditeCarte).isAfter(moment()) ? `Oui (jusqu'au ${moment(value.dateValiditeCarte).locale('fr').format('DD/MM/YYYY')})` : 'Non'} />
									<Column field="nombreCyclesEnCours" header="Contenants" sortable />
                                    <Column field="type" header="Réseau"
                                        body={value => value.type.charAt(0).toUpperCase() + value.type.slice(1)} />
                                    <Column field="createdAt" header="Inscrit le" dataType="date" sortable
                                        style={{ minWidth: '15rem' }}
                                        body={value => moment(value.createdAt).locale('fr').format('DD/MM/YYYY')} />
                                </DataTable>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}
