import apiRequest from './apiRequest';
import auth from './auth';
import alert from './alert';

async function getDataDropdown(item, selectedOutput, order, sort) {
	let items = [];

	let response = await apiRequest.send(`${item}?order=${order ? order : 'libelle'}&sort=${sort ? sort : 'ASC'}`);

	if (response.result !== null) {
		response.result.forEach(type => {
			items.push(<option value={type.id}>{selectedOutput ? type[selectedOutput] : type.libelle}</option>);
		});

		let defaultValue = items.length === 0 ? "Aucune entrée" : "Sélectionnez une valeur";
		items.unshift(<option value="">{defaultValue}</option>);
	}

	return items;
}

async function getProDropdown(role) {
	let items = [];

	let response = await apiRequest.send(`pro?order=libelle&sort=ASC&role=${role}`);

	if (response.result !== null) {
		response.result.forEach(type => {
			items.push(<option value={type.id}>{type.libelle ?? type.fullname}</option>);
		});

		let defaultValue = items.length === 0 ? "Aucune entrée" : "Sélectionnez une valeur";
		items.unshift(<option value="">{defaultValue}</option>);
	}

	return items;
}

async function getProduitsNbCaissesDropdown() {
	let items = [];

	let response = await apiRequest.send('produit?order=libelle&sort=ASC');

	if (response.result !== null) {
		response.result.forEach(type => {
			items.push(<option value={type.id}>Caisse {type.libelle} ({type.nbParCaisse} unités)</option>);
		});

		let defaultValue = items.length === 0 ? "Aucune entrée" : "Sélectionnez une valeur";
		items.unshift(<option value="">{defaultValue}</option>);
	}

	return items;
}

function pluriel(mot, variable, afficherVariable = true, terminaison = 's') {
	return variable > 1 || variable < -1
		? afficherVariable
			? `${variable} ${mot}${terminaison}`
			: `${mot}${terminaison}`
		: afficherVariable ? `${variable ?? 0} ${mot}` : `${mot}`;
}

const utils = {
	getDataDropdown,
	getProDropdown,
	getProduitsNbCaissesDropdown,
	apiRequest,
	auth,
	alert,
	pluriel
}

export default utils;